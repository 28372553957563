.home-banner{
    background: #313131 url("../../assets/home-banner.jpg");
    background-repeat: no-repeat;
    padding: 175px 0px;
    background-size: cover;
    width: 100%;
}

.banner-content {
    background: rgb(0 0 0 / 0.32);
    padding: 50px;
    max-width: 750px;
    border-radius: 2px;
}

.banner-content h2 {
    color: #fff;
    font-weight: 700;
    font-size: 48px;
    margin: 0;
    padding-bottom: 15px;
}

.banner-content p {
    color: #fff;
    max-width: 480px;
    font-size: 16px;
}


.home-category {
    padding: 80px 0px;
}

.scrollable-category .col-md-3 {
    flex: 0 0 20%;
    max-width: 20%;
}

.category-thumb img {
    width: 100%;
    height: 125px;
    object-fit: cover;
}

.category-info {
    border: 1px solid #efefef;
    padding: 15px 20px;
}

.category-info h2 {
    font-size: 16px;
    margin: 0;
    padding-bottom: 2px;
    font-weight: 600;
}

.category-info a {
    color: #777;
    font-size: 12px;
    font-weight: 500;
}

.category-info a:hover {
    text-decoration: none;
}

.category-box {
    position: relative;
    margin-bottom: 30px;
}



.title {
    text-align: center;
    margin: 0;
    padding-bottom: 50px;
    font-weight: 400;
    font-size: 30px;
}

.title span {
    font-weight: 700;
}

.scrollable-category {
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollable-category::-webkit-scrollbar {
    display: none;
}



.featured-store{
    padding: 80px 0px;
    background: #fff;
}

.featured-store-thumb img {
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.featured-store-info {
    box-shadow: 0px 0px 10px #00000014;
    padding: 15px 20px;
    background: #fff;
}

.featured-store-info h2 {
    font-size: 17px;
    margin: 0;
    padding-bottom: 5px;
    font-weight: 600;
}

.featured-store-info a {
    color: #777;
    font-size: 12px;
    font-weight: 500;
}

.featured-store-info a:hover {
    text-decoration: none;
}

.featured-store-box {
    position: relative;
    margin-bottom: 30px;
}

.featured-store-info p {
    font-size: 13px;
    margin: 0;
    padding-top: 5px;
}


.featured-store .title {
    text-align: left;
}

.featured-rating i {
    color: #ffd335;
}

.featured-rating span {
    font-weight: 600;
}


.testimonial-box {
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
}

.testimonial-thumb img {
    width: 80px;
    height: 80px;
    margin: 0px auto;
    display: block;
    border-radius: 100%;
    border: 5px solid #ffffff;
    box-shadow: 0px 0px 10px #0000001a;
}

.testimonial-info h2 {
    text-align: center;
    font-size: 18px;
    margin: 0;
    padding: 15px 0px 5px;
}

.testimonial-info {
    text-align: center;
}

.testimonial-info span {
    color: #777;
    font-size: 12px;
    padding-bottom: 19px;
    display: block;
}

.testimonial-info p {
    color: #777;
    font-size: 14px;
    margin: 0;
}

.scrollable-testimonial {
    height: 556px;
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollable-testimonial::-webkit-scrollbar {
    display: none;
}

.scrollable-testimonial .testimonial-box:last-child {
    margin: 0;
}