.navbar-light .navbar-brand img {
    max-width: 180px;
}

.header {
    width: 100%;
    background: #fff;
    position: relative;
    z-index: 9;
    box-shadow: 0px 0px 10px #00000014;
}

.header .navbar {
    justify-content: space-between;
    align-items: center;
    padding: .5rem 0rem;
}


.btn-primary:hover, .btn-primary:focus {
    color: #fff;
    background-color: #373373;
    border-color: #373373;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #373373;
    border-color: #373373;
}




.author__access_area ul {
    margin: 0;
}

.btn-gradient.btn-gradient-two:hover, .btn-gradient.btn-gradient-two:focus {
    color: #fff;
}

.author__access_area ul li > span {
    color: #777;
    display: inline-block;
    padding: 0 10px;
}


 .author__access_area ul li .access-link {
    color: #777;
    cursor: pointer;
    font-weight: 500;
}



.navbar-light .navbar-nav .nav-link {
    color: #777!important;
    font-weight: 500;
    margin-right: 40px;
    font-size: 15px;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #f1454f!important;
}

.author__access_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
}

.author__access_area ul li .access-link:hover {
    text-decoration: none;
    color: #018cce;
}


.form-control {
    font-size: 14px!important;
}

.toggle img {
    max-width: 34px;
}

.p-name h5 {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    padding-bottom: 2px;
}

.my-profile-menu .nav-link {
    padding: 0;
    font-size: 11px;
    cursor: pointer;
}

.p-pic img {
    width: 30px;
    height: 30px;
    border-radius: 5px;
}

.my-profile-menu {
    display: flex;
    align-items: center;
}

.my-profile-menu .dropdown-menu {
    min-width: 8rem;
}

.my-profile-menu .dropdown-item {
    font-size: 12px;
    padding: 5px 10px;
    color: #555;
}

.my-profile-menu .dropdown-item:active {
    background: #f8f9fa;
}

.p-pic {
    padding-right: 5px;
}

.header-left {
    display: flex;
    align-items: center;
    width: 40%;
}

.header-right {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
}

.toggle {
    margin-right: 30px;
    cursor: pointer;
}

.search-container .form-control {
    font-size: 14px!important;
    border: none;
    max-width: 250px;
    width: 100%!important;
    margin: 0!important;
}

.search-container .form-control:focus{
    outline: none;
    box-shadow: none;
}

.search-container .dropdown .btn {
    background: transparent;
    color: #777;
    font-size: 14px;
    border: none;
    width: 100%;
    text-align: left;
    border-right: 1px solid #dadada;
    border-radius: 0;
    padding: 0px 15px;
}

.search-container {
    width: 70%;
}

.search-container .dropdown .btn:hover, .search-container .dropdown .btn:focus, .search-container .show>.btn-primary.dropdown-toggle, .search-container .btn-primary:not(:disabled):not(.disabled):active, .search-container .show>.btn-primary.dropdown-toggle{
    border-right: 1px solid #dadada!important;
}

.search-container .btn, .search-container .btn:hover {
    background: transparent;
    color: #777;
    border: none;
}

.search-container .btn:focus {
    box-shadow: none!important;
}

.search-container .form-inline {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    justify-content: space-between;
    box-shadow: 0px 3px 8px #0000000d;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    background: transparent!important;
    color: #777!important;
    border: none!important;
}


.search-container .dropdown {
    max-width: 150px;
    width: 100%;
}

.search-container .dropdown-item{
    font-size: 12px;
    color: #777;
}


.search-container .dropdown-toggle::after{
    background: url("../../assets/arrow.png");
    width: 13px;
    height: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    position: absolute;
    right: 15px;
    top: 7px;
    margin: 0;
}

/********** Sidebar *********/


.sidebar-wrapper {
    width: 60vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -61vw;
    z-index: 9999;
    padding: 55px;
    background: #ffffff;
    -webkit-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    -o-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    overflow-y: auto;
    box-shadow: 0px 10px 20px #0000005e;
}

.sidebar-wrapper.expand {
    left: -1px;
}

.sidebar-menu {
    position: relative;
    padding: 50px 50px;
}

.sidebar-menu ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}



.close-icon {
    position: absolute;
    right: -20px;
    top: -20px;
    cursor: pointer;
}

.close-icon img {
    width: 30px;
    opacity: 0;
    transition: all 0.5s;
}

.close-icon.show img {
    width: 30px;
    opacity: 1;
}

.sidebar-menu ul li a:hover {
    color: #018ccf;
    text-decoration: none;
}

.side-nav-content ul.main-navigation {
    margin: -45px 0;
}

.side-nav-content ul.main-navigation li {
    margin: 45px 0;
}

.side-nav-content ul.main-navigation li a {
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}

.side-nav-content .axil-contact-info-inner h5.title, .side-nav-content .axil-contact-info-inner span.title {
    font-size: 18px;
    line-height: 1;
    color: #333333;
    font-weight: 500;
    display: block;
    margin-bottom: 15px;
    text-align: left;
    padding-bottom: 0;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 4px;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info .address p a {
    color: #757589;
    text-decoration: none;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info .address p i {
    padding-right: 14px;
    font-size: 14px;
    color: #333333;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info + .axil-contact-info {
    margin-top: 30px;
}

ul.social-share {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-decoration: none;
    margin: -10px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

ul.social-share li {
    margin: 10px;
}

ul.social-share.style-rounded li a {
    width: 32px;
    display: block;
    height: 32px;
    line-height: 32px;
    border-radius: 100%;
    background: #333333;
    text-align: center;
    color: #757589;
    text-decoration: none;
    font-size: 15px;
}

ul.social-share.style-rounded li a i {
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info .address + .address {
    margin-top: 30px;
}
address{
    margin-bottom: 15px;
}

