@import "https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css" ;
@import "https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap";

.admin-view .header,
.admin-view .footer {
  display: none;
}

.admin-wrapper {
  overflow-x: hidden;
  font-family: 'Nunito', sans-serif!important;
}

.admin-view .main_content {
  padding: 0;
}

.header-view {
  text-align: center;
  position: relative;
  z-index: 99;
}

.header-top-box h1 {
  font-size: 30px;
  padding: 5px 0px;
}

.admin-content-view {
  display: flex;
}

.admin-menu {
  min-height: 100vh;
  border-right: 1px solid#262f3e;
  width: 250px;
  background: #262f3e;
  transition: all 0.5s;
}

.admin-content {
  padding: 30px 20px;
}

.admin-container {
  width: calc(100% - 250px);
  background: #f6f7fb;
  transition: all 0.5s;
}

.admin-menu.menu-hide {
  width: 0;
  opacity: 0;
}

.admin-container.wrapper-expand {
  width: 100%;
}

.menu-list ul {
  margin: 0;
  padding: 0;
}

.menu-list ul a {
  padding: 18px 25px;
  display: block;
  color: #8091a1;
  text-decoration: none;
  transition: all 0.5s;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
}

.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background: #fff;
  min-height: 60px;
  box-shadow: 0px 0px 15px #33333312;
}

.menu-logo {
  width: 250px;
  text-align: center;
  background:#262f3e;
  padding: 11px 10px;
  border-bottom: 1px solid #2d3646;
}

.menu-logo h2 {
  font-weight: 600;
  font-size: 25px;
  color: #fff;
  margin: 0;
}

.admin-info img {
  width: 35px;
  height: 35px;
  object-fit: contain;
  border-radius: 50px;
  margin-right: 0px;
}

.admin-info {
  display: flex;
  align-items: center;
}

.menu-logo img {
  max-width: 150px;
  width: 100%;
}

.menu-toggle img {
  width: 32px;
  opacity: 0.9;
  transition: all 0.5s;
  cursor: pointer;
}

.menu-toggle img:hover {
  opacity: 1;
}

.menu-list {
  padding-top: 40px;
}

.menu-list ul a:hover {
  color: #fff;
  background: #262f3e;
}

.menu-list ul a.active {
  color: #ddd;
}

.admin-info h3 {
  margin: 0;
  font-size: 14px;
  color: #212529;
  font-weight: 600;
}

.menu-list ul a i {
  margin-right: 10px;
  font-size: 20px;
  vertical-align: bottom;
}

.dashboard .card {
  margin-bottom: 15px;
  margin-top: 15px;
  border-color: #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.dashboard .h4 {
  color: #898b8a;
}

.dashboard .text-muted {
  color: #abb2b8!important;
}

.menu-toggle a {
  color: #555;
  font-size: 20px;
}

.admin-title {
  margin: 0;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: #555;
}

.admin-title a {
  font-size: 14px;
  font-weight: 500;
  color:#a5a5a5;
}

.admin-title a:hover {
  text-decoration: none;
  color: #262f3e;
}

.font-weight-600 {
  font-weight: 600;
}

.dashboard .fa {
  font-size: 40px;
  opacity: 0.2;
}

.dashboard .card-body {
  cursor: pointer;
  padding: 30px 20px;
}

.dashboard .card-body .fa {
  transition: all 0.5s;
}

.dashboard .card-body:hover .fa {
  opacity: 1;
  color: #262f3e !important;
}

.user-list .table-responsive > .table-bordered .btn {
  background: #373373;
  border: none;
  padding: 5px 20px;
  font-size: 14px;
  min-width: 90px;
}

.user-list .table td,
.table th {
  padding: 15px 20px;
  vertical-align: middle;
}

.user-list .table td p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  margin: 0;
}

.user-list .table-responsive > .table-bordered {
  border: 0;
  background: #fff;
}

.user-list .table td {
  font-size: 14px;
}

.user-list .table td a {
  color: #478adc;
}

.action-buttons .btn {
  margin: 0px 5px;
}

.block-btn {
  background: #f1454f !important;
}

.approve-btn {
  background: #37a864 !important;
}

.edit-btn {
  background: #6d6d6d !important;
}

.admin-search {
  text-align: right;
  padding-bottom: 25px;
}

.admin-search .form-inline {
  justify-content: flex-end;
}

.admin-search .btn {
  background: #555555;
  border: none;
  padding: 7px 15px;
  font-size: 14px;
}

.admin-search .form-control {
  font-size: 13px;
  padding: 8px 10px;
  height: auto;
}

.admin-login {
  background: #f9f9f9;
  padding: 100px 0px;
  min-height: 100vh;
}

.admin-login-box {
  padding: 50px 40px;
  max-width: 500px;
  margin: 0px auto;
  background: #fff;
}

.admin-login-box .form-group .form-control {
  height: auto;
  padding: 10px 20px;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 4px;
  font-size: 14px;
}

.admin-login-box .form-group .form-control:focus {
  box-shadow: none;
  background: transparent;
}
.admin-login-box input:-internal-autofill-selected {
  background: transparent !important;
}

.admin-login-box h2 {
  font-size: 35px;
  text-align: center;
  font-weight: 600;
  color: #333;
  padding-bottom: 20px;
}

.admin-detail {
  display: flex;
  align-items: flex-start;
  padding: 20px 15px;
}

.admin-pic img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 3px solid #fff;
}

.admin-name span {
  font-size: 12px;
  color: #d0cdcd;
}

.admin-name h4 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 5px;
}

.admin-name {
  padding-left: 20px;
  padding-top: 5px;
}

.menu-list ul h5 {
  font-size: 12px;
  text-transform: uppercase;
  padding: 0px 25px;
  margin-top: 20px;
  color: #fff;
  margin-bottom: 10px;
}

ul.sub-menu {
  background: #5f5f5f;
  display: none;
}

ul.sub-menu li a {
  font-size: 13px;
  padding-left: 50px;
}

ul.sub-menu li a i {
  margin-right: 2px;
}

.menu-list ul li {
  position: relative;
}

.menu-list ul > li.has-menu > a:after {
  content: '\f107';
  font-family: FontAwesome;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: all 0.5s;
}

ul.sub-menu.menu-open {
  display: block;
}

.menu-list ul > li.has-menu.menu-expand > a:after {
  transform: rotate(180deg);
}

.admin-info .dropdown-toggle {
  background: transparent;
  font-size: 12px;
  color: #333;
  border: none;
}

.admin-info .dropdown-toggle:hover,
.admin-info .btn-primary:not(:disabled):not(.disabled):active:focus,
.admin-info .dropdown-toggle:focus,
.admin-info .show > .btn-primary.dropdown-toggle:focus {
  background: transparent;
  font-size: 12px;
  color: #333;
  border: none;
  box-shadow: none;
}

.admin-info .btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent;
  border-color: transparent;
  color: #333;
}

.admin-info .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  color: #333;
}

.admin-info .dropdown-item {
  font-size: 13px;
  padding: 8px 20px;
  font-weight: 600;
  color: #999;
}

.admin-info .dropdown-menu {
  min-width: 6rem;
  left: -5px !important;
}

.admin-search .form-label {
  padding-right: 20px;
}

.admin-search select.form-control {
  max-width: 150px;
  width: 100%;
}

.add-distributor {
  padding: 50px;
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 50px;
}

.add-distributor .form-group {
  margin-bottom: 20px;
}

.add-distributor .admin-title {
  padding-bottom: 30px;
}

.distributor-button .d-btn {
  background: #37a864;
  border: none;
  padding: 10px 30px;
  font-size: 15px;
}

.distributor-form label {
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

.user-list .tab-content {
  padding: 30px;
  background: #fff;
  border: 1px solid #dee2e6;
  margin-top: -1px;
  padding-top: 50px;
}

.user-list .nav-tabs .nav-link {
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  width: 50%;
  text-align: center;
}

.user-list .nav-tabs .nav-link:focus,
.user-list .nav-tabs .nav-link:hover {
  outline: none;
}

.font-weight-700 {
  font-weight: 700!important;
}


.category-popup {
  font-family: 'Nunito', sans-serif!important;
}


.admin-content-view .form-control, .admin-content-view .form-control::placeholder {
  color: #8091a1;
}


.service-available {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.service-available label {
  width: 100%;
}

.service-available .form-check {
  margin-right: 20px;
  color: #8091a1;
}


/************ Services List ****************/

.partner-services-content {
  padding: 20px 0px;
}

.header-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-buttons .btn {
  margin-left: 10px;
  border: 1px solid transparent;
  min-width: 100px;
  font-weight: 500;
  font-size: 14px;
  background: #262f3e;
}

.header-buttons .btn:hover {
  border: 1px solid transparent;
  background: #262f3e;
}

.header-buttons .dropdown-toggle::after {
  display: none;
}

.light-btn .btn {
  background: #ffffff;
  color: #333;
  box-shadow: 0 3px 5px 0 rgba(164, 173, 186, 0.25);
}

.header-buttons .dropdown-item {
  font-size: 14px;
  font-weight: 500;
  color: #555;
}

.header-buttons .dropdown a {
  display: block;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  color: #555;
}

.dropdown a:hover {
  text-decoration: none;
}

.dark-btn .btn-primary:not(:disabled):not(.disabled).active,
.dark-btn .btn-primary:not(:disabled):not(.disabled):active,
.dark-btn.show > .btn-primary.dropdown-toggle {
  background: #262f3e !important;
  color: #fff !important;
  border: 1px solid #262f3e !important;
}

.dark-btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.dark-btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.dark-btn.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.light-btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.light-btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.light-btn.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  background: #ffffff;
  color: #333;
  box-shadow: 0 3px 5px 0 rgba(164, 173, 186, 0.25);
}

.service-list {
  position: relative;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
}

.service-list h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 0;
  text-transform: uppercase;
  color: #666;
  letter-spacing: 0.5px;
}

.service-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
}

.service-list-header [class*='service-'] {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  color: #8091a1;
  font-weight: 600;
}

.service-list-header .service-discount {
  text-decoration: inherit;
}


.service-list-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
  padding: 15px 10px;
  border: 1px solid #eaeaea;
  margin-top: -1px;
  cursor: pointer;
}

.service-list-items [class*='service-'] {
  padding: 10px;
  font-weight: 500;
  text-align: left;
}

.service-name {
  font-weight: 600 !important;
  font-size: 15px;
  width: 31%;
  text-align: left !important;
}

.service-name span {
  display: block;
  font-size: 12px;
  color: #999;
  font-weight: 500 !important;
}

.service-discount {
  text-decoration: line-through;
  color: #999;
  width: 10%;
}

.service-hour {
  width: 15%;
}

.service-price {
  font-size: 16px;
  font-weight: 700 !important;
  width: 10%;
}

.service-extra{
  width: 17%;
}

.add-category textarea {
  height: 120px !important;
}

.category-popup .modal-header .close {
  position: absolute;
  right: 15px;
  top: 13px;
  font-size: 35px;
  font-weight: 300;
  padding: 15px;
}

.category-popup .modal-title {
  font-weight: 700;
  font-size: 20px;
  color: #333;
}

.category-popup .modal-header {
  justify-content: center;
}

.category-popup .modal-body {
  padding: 30px;
}

.category-popup .modal-header .close:focus {
  outline: none;
  box-shadow: none;
}

.add-category .l-btn.btn.btn-primary {
  background:#262f3e;
  padding: 10px 40px;
  font-size: 15px;
}

.add-category .btn-primary:not(:disabled):not(.disabled):active {
  background: #262f3e!important;
  color: #fff!important;
}

.service-list-items-multiple-service .service-list-items {
  border: none;
  padding: 0px 0px;
}

.service-list-items-multiple-service {
  position: relative;
  background: #fff;
  cursor: pointer;
  border: none;
  padding: 10px 0px;
  margin: 0;
  border-top: 1px solid #f7f7f7;
}

.service-list-items-multiple-service h4 {
  font-weight: 600 !important;
  font-size: 15px;
  width: 55%;
  text-align: left !important;
  padding: 10px;
}

.action-icon-img .btn {
  background: transparent;
  border: none;
  color: #777;
  font-size: 20px;
}

.action-icon-img .btn::after {
  display: none;
}

.action-icon-img {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.action-icon-img .btn:hover,
.action-icon-img .btn:focus {
  background: transparent;
  color: #777;
  border: none;
  box-shadow: none;
  outline: none;
}


.no-result {
  background: #fff;
  padding: 50px;
  text-align: center;
  box-shadow: 0px 0px 20px #0000000d;
  border-radius: 5px;
}

.no-result h4{
  margin: 0;
}

.header-buttons .btn:before {
  content: "\f067";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  margin-right: 4px;
}

/************ Add Services ****************/

.partner-add-services-content {
  padding: 20px 0px;
  background: #fff;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 5px;
}

.add-service-box {
  background: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 50px;
  cursor: pointer;
  
}

.add-service-box.bdr-right {
  border-right: 1px dashed #d1d3d8;
}

.add-service-box img {
  width: 30px;
  opacity: .5;
}

.add-service-box h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 5px;
  padding-top: 10px;
  color: #808080;
}

.add-service-box p {
  color: #777;
  max-width: 250px;
  margin: 0px auto;
}

.add-service-box a {
  color: #333;
  display: block;
}

.add-service-box a:hover {
  text-decoration: none;
  color: #333;
}

/* .single-services .admin-title,
.package-services .admin-title,
.add-services .admin-title {
  justify-content: center;
} */

.single-services .admin-title a,
.package-services .admin-title a,
.add-services .admin-title a {
  position: absolute;
  right: 0;
}

/************** Single Service  ****************/

.service-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  margin-bottom: 20px;
}

.service-header {
  padding: 25px 25px;
  padding-bottom: 10px;
}

.service-body {
  padding: 15px 25px;
  
}

.service-header h2 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #8091a1;
  letter-spacing: 0.5px;
}

.service-header p {
  margin: 0;
  color:#8091a1;
}

.treatment-info {
  margin: 0;
  color: #8091a1;
  font-size: 12px;
  padding-top: 8px;
}

.single-services-content textarea {
  height: 120px !important;
}

.package-services-content textarea {
  height: 100px !important;
}

.online-booking {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  color: #8091a1;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.sales-setting h5 {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #8091a1;
  letter-spacing: 0.5px;
}

.sales-setting {
  padding-bottom: 10px;
}

.sales-setting p {
  color: #8091a1;
} 

.staff-item {
  display: flex;
  align-items: center;
  padding: 15px 15px;
  width: 32%;
  font-weight: 600;
  color: #333;
  flex-wrap: wrap;
  position: relative;
  border: 1px dashed #ddd;
  border-radius: 5px;
  text-align: center;
  flex-flow: column;
  font-size: 16px;
  margin-bottom: 5px;
}

.staff-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0px 10px;
  margin-bottom: 10px;
}

.staff-item .form-check {
  position: absolute;
  right: 6px;
  top: 6px;
}

.staff-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.select-all-staff {
  padding-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
  color: #8091a1;
}

.staff-copmmision {
  padding-top: 30px;
}

.staff-copmmision h5 {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #8091a1;
  letter-spacing: 0.5px;
}

.staff-copmmision p {
  color: #8091a1;
}

.extra-time h5 {
  font-weight: 600;
  font-size: 17px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.pricing-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pricing-input .form-group {
  width: 49%;
}

.pricing-input .form-group.full {
  width: 100%;
}

.pricing-option {
  background: #f6f7fb;
  padding: 20px;
  border-radius: 5px;
  margin: 10px 0px;
  position: relative;
}

.remove-pricing {
  position: absolute;
  right: 10px;
  color: #fb3333;
  font-size: 20px;
  cursor: pointer;
}

.pricing-option h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #8091a1;
  letter-spacing: 0.5px;
}

.add-pricing-option span {
  cursor: pointer;
  font-weight: 600;
  color: #2577ff;
  font-size: 15px;
  background: #ffffff;
  padding: 10px 0px;
  border-radius: 4px;
}

.single-services-content .online-booking {
  position: absolute;
  right: 20px;
  bottom: 30px;
}

.add-pricing-option {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-button.service-save {
  padding: 20px 0px;
  display: flex;
  justify-content: flex-start;

}

.service-save .l-btn.btn.btn-primary {
  background: #262f3e;
}

.service-save .btn {
  max-width: 150px;
  margin-right: 10px;
}

.service-category-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f1f1;
  padding: 12px 15px;
  border-radius: 5px;
  flex-wrap: wrap;
}

.service-category-list-items ul {
  margin: 0;
}

.service-category-list-items ul li {
  font-weight: 500;
  font-size: 16px;
}

.category-edit span {
  color: #018bcf;
  font-weight: 600;
  cursor: pointer;
}

.service-category-list-items {
  width: 80%;
}

.category-edit {
  width: 20%;
  text-align: right;
}

.select-categories .select-list {
  padding: 15px 0px;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.select-categories .select-list h5 {
  margin: 0;
  font-size: 18px;
}

.select-categories .select-list p {
  margin: 0;
  font-size: 12px;
  color: #999;
}

.select-categories .select-list:last-child {
  border: none;
}

.select-categories + .login-button {
  margin-top: 20px;
}

.add-service-price i {
  margin-left: 10px;
  color: #f34949;
}

.extra-option h5 {
  padding-top: 15px;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 15px;
  text-transform: uppercase;
  color: #8091a1;
  letter-spacing: 0.5px;
}

.select-services .select-categories {
  padding-bottom: 20px;
}

.select-service-popup .modal-dialog {
  max-width: 800px;
}

.accordion .btn {
  width: 100%;
  text-align: left;
  color: #333;
  text-decoration: none;
  padding: 10px 0px;
  font-size: 20px;
  font-weight: 500;
}

.accordion .btn:focus {
  box-shadow: none;
}

.accordion .card-header {
  background: #fff;
  border: none;
  padding: 0;
}

.accordion .card {
  border: none;
  border-bottom: 1px solid #ddd !important;
}

.accordion .btn:hover,
.accordion .btn:focus {
  color: #333;
  text-decoration: none;
}

.accordion .btn i {
  position: absolute;
  right: 0;
  top: 15px;
}

.accordion .card:last-child {
  border-bottom: none !important;
}

.accordion .card-body {
  padding: 0px 20px;
}

.add-service-price {
  position: absolute;
  right: 10px;
  top: 20px;
  font-weight: 600;
  font-size: 18px;
}


.service-pricing .input-group-prepend {
  padding: 0px 10px;
  border: 1px solid #ced4da;
}


button.l-btn.delete-btn.btn.btn-primary {
  background-color: #ea3333 !important;
}


.pricing-input .input-group-prepend {
  margin-right: 10px;
}



.partner-header h2 {
  font-size: 16px;
  text-transform: uppercase;
  color: #8091a1;
  margin: 0;
}

.partner-header {
  padding-bottom: 25px;
}

.partner-table table thead th {
  color: #8091a1;
  font-weight: 700;
  border: none;
  padding: 15px 10px;
  border-top: 1px solid #f1f1f1;
  letter-spacing: 0.2px;
}

.partner-table table tbody td {
  color: #808e9b;
  padding: 25px 10px;
  font-size: 15px;
  border-top: 1px solid #f1f1f1;
}


/******************************* Media SCreen ******************************/

@media screen and (max-width: 767px) {
  .admin-container {
    width: 100%;
  }

  .admin-menu {
    width: 0;
  }

  .admin-search .form-inline {
    justify-content: center;
  }

  .admin-search .btn {
    margin-top: 10px;
  }

  .user-list .table-responsive > .table-bordered .btn {
    min-width: 100px;
    margin-bottom: 5px;
  }

  .admin-menu.menu-hide {
    width: 250px;
  }
}

.package-services .l-btn.btn.btn-primary {
  width: auto !important;
}
.package-services .delete-btn {
  background-color: #ea3333 !important;
}
.disabled {
  cursor: not-allowed;
  opacity: .3;
  background: #ccc;
}
.partner-table table .disabletable tr:first-child {

  opacity: .3;
  background: #ccc;
  cursor: not-allowed;
}
.disable-block {
  opacity: .5;
  cursor: not-allowed;
}

.disable-block input {
  pointer-events: none;
}

.disabled input {
  pointer-events: none;
}

.pdd-bt-89{
  padding-bottom: 89px;
}

.advance-pricing-popup{
  font-family: 'Nunito', sans-serif!important;
}
.advance-pricing-box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background: #f7f7f7;
  border-radius: 5px;
}

.advance-pricing-box .form-group {
  width: 24%;
}

.special-price-associate {
  padding: 20px 0px;
}

.associate-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
}

.associate-user img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.associate-user {
  display: flex;
  align-items: center;
  width: 40%;
}

.associate-user p {
  margin: 0;
  padding-left: 15px;
  font-weight: 600;
  color: #8091a1;
}

.price-associate {
  width: 25%;
  padding-right: 10px;
}

.duratiion-associate {
  width: 25%;
}

.special-price-associate h4 {
  font-weight: 600;
  color: #555;
  font-size: 20px;
}
.advance-pricing-popup .modal-title {
  font-weight: 700;
  color: #555;
}

.associate-pricing-buttons {
  text-align: right;
}

.associate-pricing-buttons .btn.btn-default {
  border: 1px solid #ddd;
  color: #999;
  margin-right: 10px;
}

.associate-pricing-buttons .btn.btn-primary {
  background: #262f3e;
  border-color:#262f3e;
}

.associate-pricing-buttons .btn.btn-primary:hover {
  border: 1px solid #262f3e!important;
}