@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap";
@import "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";


body {
  background-color: #ffffff!important;
  margin: 0;
  font-family: 'Montserrat', sans-serif!important;
  font-size: 14px!important;
}
