.signup {
  background: #f9f9f9;
}

.register-section {
  padding: 80px 0px;
}
.register-form {
  padding: 50px 40px;
  max-width: 600px;
  margin: 0px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px;
}

.head-logo img {
  max-width: 100px;
  margin: 0px auto;
  display: block;
  border: 2px solid #018bcf;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.register-form h1 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  max-width: 500px;
  margin: 0px auto;
  color: #333;
}

.register-form h1 span {
  color: #018bcf;
}

.register-form .rigister-tab {
  margin-top: 40px;
}

.rigister-tab p {
  text-align: center;
  padding-top: 30px;
  font-size: 14px;
  color: rgb(160, 160, 160);
  margin: 0;
  font-weight: 500;
}

.terms-link {
  color: #018bcf;
  text-decoration: underline;
}

.terms-link:hover {
  color: #c5c5c5;
}

.dis-show .form-check {
  display: inline-block;
}

.dis-show p {
  font-size: 14px;
  color: rgb(160, 160, 160);
  margin: 0;
  font-weight: 500;
  padding: 0 !important;
}

.dis-show {
  display: flex;
  align-items: center;
}
.s-btn.btn.btn-primary {
  padding: 12px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 4px;
  background: #018bcf;
  border: none;
  transition: all 0.5s;
  width: 100%;
  margin-top: 20px;
}

.s-btn.btn.btn-primary:hover,
.s-btn.btn.btn-primary:focus,
.s-btn.btn.btn-primary:active {
  background: #018bcf;
  box-shadow: none;
  border: none;
}

.register-form .form-group .form-control {
  height: auto;
  padding: 12px 20px;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 4px;
  font-size: 14px;
}

.register-form .form-group .form-control:focus {
  box-shadow: none;
  background: transparent;
}
.register-form input::-internal-autofill-selected {
  background: transparent !important;
}

.signup-popup .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}



 .form-label {
    font-size: 14px;
    color: #8091a1;
    font-weight: 600;
}

.signup-popup .modal-header .close:focus {
  outline: none;
}

.signup-popup .close {
  color: #999;
  text-shadow: none;
  font-weight: 400;
  font-size: 30px;
}

.signup-popup .close:hover {
  color: #777;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border: 1px solid #dadada;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.error {
  position: relative;
  font-weight: 400 !important;
  font-size: 10px !important;
  color: #f91c1c !important;
  text-transform: capitalize;
  text-align: left !important;
  padding: 0 !important;
  margin: 0px !important;
}

.number-fields {
  display: flex;
  align-items: center;
}

.number-fields .dropdown .btn {
  height: auto;
  padding: 11px 20px;
  background: transparent;
  border: 1px solid #a58d8d !important;
  border-radius: 4px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  line-height: 23px;
  color: #777;
}

.number-fields .form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.number-fields .dropdown .btn:focus {
  border: 1px solid #dadada;
  border-right: 0;
  box-shadow: none;
}

.number-fields .btn-primary:not(:disabled):not(.disabled):active {
  border: 1px solid #dadada !important;
  border-right: 0 !important;
  box-shadow: none;
}

.number-fields .show > .btn-primary.dropdown-toggle {
  border: 1px solid #dadada !important;
  border-right: 0 !important;
  box-shadow: none !important;
}

.number-fields .dropdown-item {
  font-size: 14px;
  color: #777;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
