.login {
    background: #f9f9f9;
}

.login-section {
    padding: 80px 0px;
}


.login-form {
    padding: 50px 40px;
    max-width: 500px;
    margin: 0px auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px;
}


.head-logo img {
    max-width: 100px;
    margin: 0px auto;
    display: block;
    border: 2px solid#018bcf;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 20px;
}

.login-form h1 {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    color: #333;
}

.login-form h1 span {
    color:#018bcf;
}

.login-box {
    padding-top: 30px;
    max-width: 400px;
    margin: 0px auto;
}

.login-box .form-group .form-control {
    height: auto;
    padding: 12px 20px;
    background: transparent;
    border: 1px solid #dadada;
    border-radius: 4px;
    font-size: 14px;
}

.login-box .form-group .form-control:focus{
    box-shadow: none;
    background: transparent;
}
.login-box input:-internal-autofill-selected{
    background: transparent!important;
}

.form-label {
    font-size: 14px;
    color: #8091a1;
    font-weight: 600;

}

.form-group {
    margin-bottom: 35px;
}

.l-btn.btn.btn-primary {
    padding: 12px 40px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border-radius: 4px;
    background: #018bcf;
    border: none;
    transition: all 0.5s;
    width: 100%;

}

.l-btn.btn.btn-primary:hover, .l-btn.btn.btn-primary:focus, .l-btn.btn.btn-primary:active {
    background: #018bcf;
    box-shadow: none;
    border: none;
}

.login-button {
    text-align: center;
}

.login-box p {
    text-align: center;
    padding-top: 30px;
    font-size: 14px;
    color: rgb(160, 160, 160);
    margin: 0;
    font-weight: 500;

}

.signup-link {
    color:#018bcf;
    text-decoration: underline;
}

.signup-link:hover{
    color: #c5c5c5;
}

.forget-password{
    text-align: right;
}

.forget-link {
    color: #018bcf;
    font-size: 14px;
    font-weight: 500;
}

.forget-link:hover {
    color: #c5c5c5;
    text-decoration:none;
}

.forget-password {
    padding-bottom: 30px;
    margin-top: -10px;
}

.login-popup .modal-header {
    border-bottom: none;
    padding-bottom: 0;
}

.password-show {
    position: relative;
}

.password-show i {
    position: absolute;
    right: 12px;
    bottom: 16px;
    cursor: pointer;
}

.login-popup .form-label {
    font-size: 14px;
    color: #333;
    font-weight: 600;
}


.login-popup .modal-header .close:focus {
    outline: none;
}

.login-popup .close {
    color: #999;
    text-shadow: none;
    font-weight: 400;
    font-size: 30px;
}

.login-popup .close:hover {
    color: #777;
}

.modal-backdrop {
    background-color: #352929!important;
}