.staff-content {
  padding: 10px 0px;
  background: #fff;
  border-radius: 4px;
}

.staff-page .nav-tabs .nav-link {
  font-size: 15px;
  font-weight: 600;
  width: 25% !important;
  text-align: center;
  padding: 15px 15px;
  color: #8091a1;
}
.staff-page a {
  font-weight: 500;
  color: #000;
  font-size: 17px;
}
.staff-page a:hover {
  color: #3c3b3b;
}

.staff-page .nav-tabs {
  border-bottom: 2px solid #efefef;
}
.staff-page .tab-content {
  border: none;
  margin-top: 0;
  padding: 20px 20px 30px;
}

.staff-content .btn,
.staff-content .btn:hover {
  border: none !important;
}

/********* Close Staff ****************/

.close-date button {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}
.close-date {
  text-align: right;
  padding-bottom: 20px;
}
.close-date button:hover {
  background-color:#0075ff !important;
  border-color:#0075ff !important ;
}
.staff-page .nav-tabs .nav-link.active {
  color: #8091a1!important;
  background-color: transparent !important;
  border-color: transparent !important;
  border-bottom: 2px solid #0075ff!important;
}
.staff-page .nav-tabs .nav-link.active:hover,
.staff-page .nav-tabs .nav-link.active:focus {
  border-bottom: 2px solid #0075ff!important;
}
.staff-page .nav-tabs .nav-link:focus,
.staff-page .nav-tabs .nav-link:hover {
  border-color: transparent !important;
 
}

.closedatemodal .btn.btn-primary {
  background-color: #018bcf;
}
.closedatemodal .modal-content {
  width: 560px;
}
.closedatemodal .modal-header {
  border-bottom: transparent !important;
}

/*************** User Permission *************/

span.input-group-text {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

thead th.text-heading {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.user-permission button {
  background-color: #018bcf !important;
  border-color: #018bcf !important;
}
.user-12 p.text-muted {
  margin-bottom: 30px;
}

.user-permission p {
  max-width: 780px;
}

/********* Add Staff ****************/

.add-staff-content {
  background: #fff;
  padding: 40px 30px;
}

.add-staff-details .form-control {
  height: auto;
  padding: 12px 20px;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 4px;
}
.add-staff .s-btn.btn.btn-primary {
  width: 20%;
  margin-right: 10px;
  box-shadow: 0px 3px 10px #00000038;
}
.add-staff-content .nav-tabs .nav-link {
  padding: 15px 50px;
}
.add-staff-details textarea#employee_notes {
  height: 137px;
}
.c-tn.btn.btn-primary {
  background-color: #9aa09e;
}
.mt {
  margin-top: 20px;
}

.add-staff-content .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 600;
  color: #8091a1;
}

.close-date a {
  background-color:  #0075ff;
  color: #fff;
  border-color:  #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
  display: inline-block;
  box-shadow: 0px 3px 10px #00000038;
}

.close-date a:hover,
.close-date a:focus {
  color: #fff;
  text-decoration: none;
  background:  #0075ff;
}

.add-services input[type='checkbox'] {
  margin-top: -8px !important;
  margin-right: 10px;
}
.add-services .sel-1.form-label {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.add-services .form-label {
  font-weight: 500;
  color: #555;
}

/********* Add Commision ****************/

.add-commission .input-group-prepend {
  margin-right: -1px;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  padding: 3px 15px;
}

/********* StaffWorking Hours ****************/

.schedule-filters .form-control {
  max-width: 135px;
}

.pull-right {
  float: right !important;
}

.schedule-date-toolbar
  .btn-group
  > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.schedule-wrapper {
  position: relative;
  display: flex;
  min-height: 0;
}

.schedule-scrollable {
  overflow-y: auto;
  width: 100%;
  margin-top: 25px;
}

.schedule-tables {
  overflow-y: auto;
}

.schedule-employees {
  float: left;
  width: 20%;
}

.employee-table {
  border-left: 1px solid #d9d9d9;
}

.schedule-table,
.employee-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.staff-working table {
  background-color: transparent;
}

.schedule-employee-hours {
  float: left;
  width: 80%;
}

.schedule-table,
.employee-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.schedule-table th,
.employee-table th {
  padding: 10px;
}

.schedule-table th,
.schedule-table td,
.employee-table th,
.employee-table td {
  text-align: center;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  cursor: default;
  min-width: 152px;
}

.employee-table td.employee-name {
  font-weight: bold;
  vertical-align: top;
  text-align: left;
  padding: 5px 10px 0;
}

.employee-table td {
  height: 51px;
}

.schedule-hours {
  border-radius: 2px;
  color: #24334a;
  padding: 3px;
  text-align: left;
  background: #d5edf5;
  font-size: 14px;
  cursor: pointer;
}

.schedule-date-toolbar .btn-default.active,
.schedule-date-toolbar .btn-default:active,
.schedule-date-toolbar .btn-default.active:focus,
.schedule-date-toolbar .btn-default:active:focus,
.schedule-date-toolbar .btn-default:active:hover {
  background-color: #f7f7f8;
  border-color: #c2c2c2;
  color: #2c2c2c;
}

.pull-right.schedule-date-toolbar {
  border: 1px solid #ced4da;
}

input#date_from {
  display: none;
}

.closedatemodal.edithour .modal-header {
  flex-wrap: wrap;
}

.closedatemodal.edithour .modal-header span {
  width: 100%;
}

.closedatemodal.edithour .modal-header .close {
  position: absolute;
  right: 10px;
  font-weight: 500;
  font-size: 30px;
}
.cross {
  position: absolute;
  right: 0;
  padding: 0px 20px;
  color: black;
}



/*********** Calendar **********/


.schedule-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 40px;
}

.schedule-date-toolbar {
  border: 1px solid #f1f1f1;
  padding: 1px;
  margin-right: 20px;
}

.partner-table table.table-bordered thead th {
  border: 1px solid #f1f1f1;
  text-align: center;
}

.partner-table table.table-bordered tbody td {
  border: 1px solid #f1f1f1;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.partner-table table.table-bordered tbody tr td:first-child {
  text-align: left;
}

.schedule-filters .form-inline .form-control {
  min-width: 120px;
  border: 1px solid #f1f1f1;
  border-radius: 0px;
}

.partner-table table.table-bordered thead th:first-child {
  width: 180px;
  text-align: left;
}

.partner-table table.table-bordered thead th:nth-child(2) {
  width: 113px
}

.add-shift {
  background: #262f3e;
}

.add-shift i {
  color: #fff;
}

.user-permission .online-booking {
  padding: 0;
}

.user-permission .partner-table table tbody td {
  vertical-align: middle;
}

.user-permission .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.close-staff .partner-table table tbody td .btn-danger {
  margin-left: 5px;
}

.closedatemodal .btn.btn-primary {
  border: 1px solid #0075ff;
}

.closedatemodal {
  font-family: 'Nunito', sans-serif!important;
}

.closedatemodal .btn.btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.closedatemodal .btn.btn-secondary {
  background-color: #696a6b;
  color: #fff;
  border-color: #696a6b;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}
.form-group .number-fields .dropdown .btn {
  border: 1px solid #dadada!important;
}
.add-staff-content .tab-content {
  padding: 25px;
  border: 1px solid #ddd;
  margin-top: -1px;
}
.disabled-permmission {
  cursor: not-allowed;
  opacity: 0.3;
}

.disabled-permmission input[type="checkbox"] {
  pointer-events: none;
}

.disabled-permmission .slider {
  cursor: not-allowed;
}