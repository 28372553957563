@media screen and (max-width: 991px) {

.container, .container-lg, .container-md, .container-sm {
    max-width: 100%;
}

.navbar-light .navbar-brand img {
    max-width: 200px;
}

.search-container {
    width: 75%;
}

}


@media screen and (max-width: 767px) {

    .navbar-light .navbar-brand img {
        max-width: 225px;
    }

    .scrollable-category .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .header-right {
        display: none;
    }
    .banner-content h2 {
        font-size: 35px;
    }
    .banner-content {
        padding: 30px;
    }
    .banner-content p {
        font-size: 14px;
    }
    .home-banner {
        padding: 125px 0px;
    }
    .category-info {
        padding: 10px 10px;
    }
    .category-info h2 {
        font-size: 14px;
    }

    .sidebar-menu {
        padding: 10px 0px;
    }

    .side-nav-content ul.main-navigation li a {
        font-size: 14px;
    }

    .sidebar-wrapper {
        width: 80vw;
        left: -81vw;
        padding: 50px 25px;
    }

    .side-nav-content ul.main-navigation li {
        margin: 25px 0;
    }

    .side-nav-content ul.main-navigation {
        margin: 0;
    }

    .side-nav-content .axil-contact-info-inner .axil-contact-info p {
        font-size: 14px;
    }

    .side-nav-content .axil-contact-info-inner h5.title,
    .side-nav-content .axil-contact-info-inner span.title {
        font-size: 16px;
        font-weight: 600;
        padding-top: 20px;
    }

    .close-icon {
        right: -10px;
        top: -30px;
    }

    .featured-video {
        padding-bottom: 50px;
    }

    .featured-video > div {
        height: 300px !important;
    }

    .sub-title {
        padding-bottom: 30px;
    }

    .section-b-space {
        padding-bottom: 0px;
    }

    .sub-footer ul {
        flex-wrap: wrap;
    }

    .sub-footer ul li {
        width: 50%;
        text-align: center;
        padding: 5px 0px;
    }



    /******* Store ******/

    .store-listing-filter {
        flex-wrap: wrap;
    }

    .store-filter-box {
        justify-content: center;
        flex-wrap: wrap;
    }

    .store-list {
        width: 100%;
        padding: 0;
    }

    .store-image {
        width: 100%;
    }

    .store-detail {
        width: 100%;
        padding: 20px;
    }

    .store-image img {
        width: 100%;
        height: 220px;
    }


}
