.forget-section {
    padding: 50px 0px;
    background: #f9f9f9;
}

.forget-form {
    background: #fff;
    padding: 50px 30px;
    max-width: 500px;
    margin: 0px auto;
    margin-bottom: 30px;
    border-radius: 8px;
    box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px;
}

.forget-form h1 {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: #333333;
}

.forget-form h1 span {
    color: #018bcf;
}


.form-group .form-control {
    height: 40px;
    font-size: 14px;
    padding: 5px 15px;
}

.forget-form p {
    text-align: center;
    font-size: 14px;
    color: #777;
    margin-bottom: 50px;
    margin-top: 15px;
}

.forget-box {
    max-width: 400px;
    margin: 0px auto;
}

.forget-form .reset-btn {
    background: #018bcf;
    border: none;
    transition: all 0.5s;
    padding: 12px 40px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    border-radius: 4px;
    margin: 0px auto;
    display: block;
    width: 100%;
}

.reset-box {
    max-width: 400px;
    margin: 50px auto;
    margin-bottom: 0;
}

.btn-primary:not(:disabled):not(.disabled):active {
    background: #018bcf;
    box-shadow: none;
    border: none;
}

.btn-primary:hover {
    color: #fff;
    background: #018bcf;
    box-shadow: none;
    border: none;
}