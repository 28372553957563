
/**********SetUP*******************/
.account-card {
  margin-bottom: 30px;
}

.account-card h2 {
  line-height: 28px;
  margin: 0;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  color: #555;
}
.account-card a {
  font-weight: 600;
  margin-bottom: 0px;
  display: block;
  font-size: 16px;
  color: #6f6f6f;
  padding: 15px 20px;
}
.account-card .card-body {
  padding: 0;
}
.account-card .card {
  border: 1px solid #f7f7f8;
  padding: 10px;
  box-shadow: 0 0px 5px 0 rgb(164 173 186 / 8%);
  margin-bottom: 20px;
}
.account-card .card-title.h5 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.tax-head img {
  width: 10%;
}
.btn-group-toggle .active1 {
  color: #fff !important;
  background-color: #007bff !important;
}

.account-card a:hover {
  text-decoration: none;
  color: #333;
}

.input-group-prepend .fa {
  font: normal normal normal 14px/1 FontAwesome;
}

/*********************ACCOUNT SETTING*******************/

p.text-12 {
  background-color: #eef0f2;
  padding: 14px 16px;
  text-align: left;
  border-radius: 2px;
}
.info-card {
  margin-bottom: 30px;
}

.back-setup h5 {
  font-weight: 600;
}

.account-setting .card-title.h5 {
  font-weight: 600;
}
.account-setting .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem !important;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef !important;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.account-setting .card:hover {
  box-shadow: 0 5px 15px 5px rgba(164, 173, 186, 0.25);
}
.account-setting .card {
  border: 1px solid #f7f7f8;
  padding: 10px;
  box-shadow: 0 2px 5px 0 rgba(164, 173, 186, 0.25);
}

.account-save{
  text-align: right;
}
.account-save .btn{
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.account-save .btn:hover{
  border: 1px solid  #0075ff;
}

.invoice-tab-content {
  padding: 10px 0px;
  background: #fff;
  border-radius: 4px;
}

.invoice-tab-content .card {
  border: none;
}

.invoice-tab-content .card .card-body {
  padding: 10px;
}

.invoice-tab-content .online-booking {
  padding: 0;
}

.invoice-tab-content .input-group-prepend {
  align-items: center;
  margin: 10px 0px
  
}

.invoice-tab-content .input-group-prepend .form-label {
  margin: 0;
}
/*********************INVOICE*******************/

.invoice input[type='checkbox'] {
  margin-top: -8px !important;
  margin-right: 10px;
  width: 25px;
  height: 18px;
}


/*********************INVOICE-SEQUENCE*******************/


.invoice-sequence table tr td span {
  cursor: pointer;
}

.invoice-sequence .partner-table .btn.btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
}

.invoice-sequence .partner-table .btn.btn-primary:hover {
  border: 1px solid #0075ff;
}

.discount-type .btn-primary{
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.discount-type .btn-primary{
  border: 1px solid #0075ff;
}

.modal, .discount-12{
  font-family: 'Nunito', sans-serif!important;
}

.discount-12 .btn-primary {
  border: 1px solid #007bff;
}

/*********************TAXES*******************/
.taxes {
  padding: 30px 20px;
  background: #fff;
  border-radius: 4px;
}


.taxes .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.taxes .btn-primary:hover {
  border: 1px solid #0075ff;
}

.taxes .partner-table .btn-primary {
  margin-left: 5px;
  padding: .375rem .75rem;
  font-size: 1rem;
}

.taxes h5 {
  margin: 0;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  color: #555;
}
.cus-12 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tax-head span.mb-5 {
  display: inline-block;
}
.default-tax {
  border-top: 1px solid #eee;
  padding-top: 50px;
}
.tax-head {
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0 30px 0;
}

.tax-head i.fa.fa {
  font-size: 60px;
  color: #999;
  margin-bottom: 10px;
}
.taxes p {
  color: #999;
}
.tax-show .card {
  background: #f7f7f7;
  border-color: #f3f0f0;
}

.tax-show .card h5 {
  padding-bottom: 5px;
}

.tax-calculation span{
  cursor: pointer;
}

.tax-sale {
  background: #262f3e;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.tax-cal-box {
  display: flex;
  align-items: flex-start;
  padding: 20px 0px;
}

.cal-box-detail h4 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding-bottom: 5px;
  color: #555;
}

.cal-box-detail p {
  margin: 0;
  color: #999;
}

.cal-box-detail {
  padding-left: 15px;
}

.tax-cal-box .online-booking {
  padding: 0;
}

.tax-calculation-popup .modal-body {
  padding: 25px;
}

.tax-calculation-popup .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.tax-calculation-popup .btn-primary:hover {
  border: 1px solid #0075ff;
}

.tax-calculation-popup .modal-dialog{
  max-width: 600px;
}

/*********************PAYMENT TAXES MODAL*******************/

.pay-12 .modal-dialog {
  max-width: 570px !important;
}

.payment-tax {
  padding: 30px 20px;
  background: #fff;
  border-radius: 4px;
}

.payment-tax .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.payment-tax .btn-primary:hover{
  border: 1px solid #0075ff;
}

.payment-tax .partner-table .btn-primary {
  margin-left: 5px;
  padding: .375rem .75rem;
  font-size: 1rem;
}

.payment-tax .partner-table table thead th:last-child {
  width: 150px;
}

.pay-12 .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.pay-12 .btn-primary:hover {
  border: 1px solid #0075ff;
}

.discount-12 .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.discount-12 .btn-primary:hover {
  border: 1px solid #0075ff;
}


/*********************DISCOUNT TYPE*******************/

.dic-info {
  box-shadow: 0 8px 15px 5px rgba(164, 173, 186, 0.2);
  background-color: #eef0f2;
  border-radius: 4px;
  overflow: hidden;
}

.dic-info .card {
  border: 1px solid #fff;
}

/*********************DISCOUNT TYPE MODAL*******************/

.discount-12 input[type='checkbox'] {
  margin-top: -10px !important;
  margin-right: 10px;
  width: 20px;
  height: 18px;
}

.discount-12 .row .input-group-text {
  padding: 0.375rem 0.75rem !important;
  background-color: #e9ecef !important;
  border: 1px solid #ced4da !important;
}
