@import 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css';

.store-listing {
  position: relative;
}

.store-listing-cover {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 80px 0px;
  width: 100%;
}

.store-listing-cover:before {
  content: '';
  background: #000;
  opacity: 0.7;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.store-listing-cover h2 {
  position: relative;
  font-weight: 700;
  font-size: 35px;
  color: #fff;
  text-align: center;
}

.store-listing-filter {
  margin: 50px 0px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}

.store-filter-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.store-search-result p {
  margin: 0;
  color: #999;
  font-size: 16px;
  font-weight: 500;
}

.store-filter-box .switch {
  margin-left: 10px;
}

.store-filter-box .dropdown {
  margin-left: 10px;
}

.store-filter-box .dropdown .btn {
  background: transparent;
  border: 1px solid #cac9c9;
  color: #777;
  font-size: 15px;
}

.store-filter-box .dropdown .btn:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.store-filter-box .dropdown .btn-primary:not(:disabled):not(.disabled):active {
  border: 1px solid #cac9c9 !important;
}

.store-filter-box .show > .btn-primary.dropdown-toggle {
  border: 1px solid #cac9c9 !important;
}

.store-filter-box .dropdown-item {
  font-size: 14px;
}

.store-listing-view {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

.store-list {
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.store-image img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.store-image {
  width: 40%;
  position: relative;
}

.store-detail {
  width: 60%;
  padding: 10px 20px;
}

.store-favorite {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #ffffffb8;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 38px;
  border-radius: 100%;
  box-shadow: 0px 0px 5px 0px #0000001f;
}

.store-favorite i {
  color: #ff2424;
  font-size: 16px;
}

.store-detail h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 10px;
}

.store-detail ul li {
  padding: 5px 0px;
  color: #777;
  font-size: 13px;
}

.store-rating i {
  color: #ffd437;
  font-size: 16px;
  margin-right: 2px;
}

.store-detail ul {
  margin: 0;
}

.store-rating {
  padding-top: 10px;
}

.store-rating span {
  background: #4caf50;
  color: #fff;
  padding: 5px 7px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}

.store-pagination {
  text-align: center;
}

.store-pagination .pagination {
  justify-content: center;
  padding-bottom: 50px;
}

.store-pagination .page-link {
  color: #777;
  background-color: #dee2e6;
  border: 1px solid #dee2e6;
  width: 40px;
  height: 40px;
  line-height: 24px;
  margin: 0px 5px;
  border-radius: 100%;
}

.store-pagination .page-item.active .page-link {
  width: 40px;
  height: 40px;
  line-height: 24px;
  margin: 0px 5px;
  border-radius: 100%;
}

.store-pagination .page-item:first-child .page-link {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}

.store-pagination .page-item:last-child .page-link {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}

.store-pagination .page-link:hover {
  color: #777;
}

.store-detail ul li i {
  color: #ff2424;
  font-size: 16px;
  margin-right: 5px;
  vertical-align: middle;
}

.store-location {
  margin-bottom: 50px;
  display: none;
}

.store-location.show {
  display: block;
}

/******************************** Store Detail Page *******************************/

.store-slider-container {
  overflow: hidden;
}

.store-slider img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.store-slider img:focus {
  outline: none;
}

.opening-hours {
  overflow: hidden;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 30px;
  transform: translate3d(0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
  margin-top: 35px;
}

.opening-hours h3 {
  width: 100%;
  font-size: 20px;
  line-height: 40px;
  padding: 0 0 20px;
  margin: 5px 0 20px 0;
  display: block;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}

.opening-hours h3 i {
  margin-right: 10px;
  color: #fff;
  width: 40px;
  height: 40px;
  background: #018cce;
  border-radius: 50%;
  position: relative;
  top: 1px;
  text-align: center;
  line-height: 40px;
}

.opening-hours ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.opening-hours ul li {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #66676b;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 6px 0;
  transition: 0.2s;
  cursor: default;
}

.opening-hours ul li span {
  float: right;
  font-weight: 400;
  font-size: 12px;
}

.store-detail-info {
  background: #f9f9f9;
  padding: 20px 25px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.store-about {
  padding: 50px 0px;
}

.about-store {
  padding-top: 50px;
}

.store-detail-info h2 {
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #323232;
}

.store-detail-info .store-detail {
  padding: 0;
  width: 100%;
}

.store-detail-info .store-detail ul li {
  font-size: 14px;
  font-weight: 500;
}

.book-now .btn {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 20px;
  background: #018cce;
  border: none;
}

.heading-title {
  font-size: 22px;
  font-weight: 600;
  position: relative;
  display: block;
  padding-bottom: 30px;
  margin-bottom: 10px;
}

.heading-title::after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 20px;
  height: 2px;
  width: 40px;
  background-color: #018cce;
}

.about-store p {
  font-size: 15px;
  line-height: 27px;
  color: #777;
}

.about-store ul li {
  border: 1px solid #e0e0e0;
  color: #555;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 8px;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
}

.near-venue {
  padding: 50px 0px;
}

.opening-hours .form-control {
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  outline: none;
  font-size: 15px;
  color: #808080;
  margin: 0 0 16px 0;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03);
  font-weight: 400;
  opacity: 1;
  border-radius: 4px;
}

.opening-hours textarea {
  height: auto;
  line-height: 24px;
  padding: 15px;
  min-height: 130px;
  transition: none !important;
  min-width: 100%;
}

.opening-hours .btn.btn-primary {
  text-align: center;
  margin: 0 auto;
  display: block;
  border: 0;
  line-height: 28px;
  height: auto;
  padding: 10px 20px;
}

.store-review .opening-hours {
  margin-top: 0;
}

.store-review {
  padding: 50px 0px;
}

.comments ul {
  padding-left: 0;
}

.comments ul li {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 20px;
  border-radius: 4px;
  display: block;
  width: 100%;
  margin: 30px 0 0 0px;
}

.comments .avatar {
  display: inline-block;
  padding: 0 20px 0 20px;
  float: left;
  position: absolute;
  left: 0px;
}

.comments .avatar img {
  width: 80px;
  height: 80px;
  border: 5px solid rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  object-fit: cover;
}

.comments .utf_comment_content {
  color: #666;
  padding: 0 0 0px 100px;
}

.utf_star_rating_section {
  display: block;
  font-size: 15px;
  float: left;
  position: absolute;
  right: 10px;
  top: 30px;
}

.utf_star_rating_section .star {
  display: inline-block;
  margin: 0;
  padding: 0;
  float: left;
  margin-right: 4px;
  position: relative;
  color: #888;
}

.utf_star_rating_section .star:before {
  font-family: 'FontAwesome';
  content: '\f005';
  display: block;
  color: #ffc600;
}

.utf_by_comment {
  width: 100%;
  padding-bottom: 0;
  padding-top: 0px;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  display: inline-block;
  padding-right: 100px;
}

.utf_by_comment span.date {
  color: #888;
  font-size: 12px;
  font-weight: 400;
  margin: 0px 0 10px 0;
  float: none;
  display: block;
  text-align: left;
}

.utf_by_comment span.date i {
  color: #007bff;
  font-size: 14px;
  margin-right: 2px;
}

.utf_comment_content p {
  padding: 5px 0;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  line-height: 25px;
}

.utf_leave_rating {
  height: 48px;
  float: left;
  background: #fff;
  padding: 12px 15px;
  line-height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  text-align: center;
  vertical-align: middle;
  margin: 0 auto;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03);
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.utf_leave_rating input[type='radio'] {
  display: none;
}

.utf_leave_rating input[type='radio'] {
  width: 15px;
  height: 15px;
  cursor: pointer;
  box-shadow: none;
  padding: 0;
}

.utf_leave_rating label {
  font-size: 24px;
  letter-spacing: 6px;
  color: #dadada;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 0;
}

.utf_leave_rating span {
  margin-right: 10px;
  color: #808080;
  font-size: 14px;
}

.utf_leave_rating label:hover,
.utf_leave_rating label:hover ~ label {
  color: #ffc600 !important;
}

.utf_leave_rating input[type='radio']:checked ~ label {
  color: #ffc600;
}

.store-services {
  padding: 50px 0px;
}

.store-service-tab {
  padding-top: 35px;
}

.store-service-tab-content {
  padding: 40px 0px;
}

.store-service-tab .nav-tabs {
  border: none;
  justify-content: flex-start;
  border-bottom: 2px solid rgb(0 0 0 / 0.1);
  margin-bottom: 0px;
  counter-reset: a;
}

.store-service-tab .nav-tabs .nav-link::before {
  counter-increment: a;
  content: counter(a, decimal-leading-zero);
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 10px;
}

.store-service-tab .nav-tabs .nav-link {
  background: transparent;
  color: #161c23;
  border: none;
  padding: 10px 20px;
  margin: 0px 0px;
  position: relative;
  top: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
}

.store-service-tab .tab-content {
  border: none;
  margin: 0;
  padding: 0;
}

.store-service-tab .nav-tabs .nav-link.active {
  background: transparent !important;
  border: none;
  color: #007bff !important;
  border-bottom: 4px solid #007bff !important;
}

.store-service-tab .nav-tabs .nav-link.active:hover,
.store-service-tab .nav-tabs .nav-link.active:focus {
  border-bottom: 4px solid #007bff !important;
}

.store-service-tab .nav-tabs .nav-link:hover {
  border-bottom: 4px solid transparent;
}

.store-service-tab .nav-tabs .nav-link:hover,
.store-service-tab .nav-tabs .nav-link:focus {
  border: none;
  outline: none;
}

.service-selection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.service-list-view {
  width: 48%;
  display: flex;
  align-items: flex-start;
  padding: 30px 0px;
  border-bottom: 1px solid #ddd;
}

.service-checkbox .form-check {
  padding: 0;
}

.service-detail {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.service-info h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.service-info p {
  color: #999;
  margin: 0;
  padding-top: 2px;
  font-size: 14px;
}

.service-cost h5 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.service-selection .service-list-view:nth-last-child(1),
.service-selection .service-list-view:nth-last-child(2) {
  border: none;
}

.service-checkbox input[type='checkbox'] + label {
  display: block;
  margin: 0;
  cursor: pointer;
  padding: 0;
}

.service-checkbox input[type='checkbox'] {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 2em;
  height: 2em;
  margin: 0;
  cursor: pointer;
}

.service-checkbox input[type='checkbox']:focus {
  outline: none;
}

.service-checkbox input[type='checkbox'] + label:before {
  content: '\2714';
  border: 1px solid #cecece;
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  border-radius: 100%;
  text-align: center;
  line-height: 2em;
}

.service-checkbox input[type='checkbox'] + label:active:before {
  transform: scale(0);
}

.service-checkbox input[type='checkbox']:checked + label:before {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.service-checkbox input[type='checkbox']:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

.service-checkbox input[type='checkbox']:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.slick-prev {
  left: 10px;
  background: #000000a3;
  z-index: 9;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.slick-next {
  right: 10px;
  background: #000000a3;
  z-index: 9;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: #fff;
  outline: 0;
  background: #000000a3;
}

.slick-prev:before {
  background: url('../../assets/partner/prev-icon.png');
  width: 10px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  display: block;
  margin: 0px auto;
}

.slick-next:before {
  background: url('../../assets/partner/next-icon.png');
  width: 10px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  display: block;
  margin: 0px auto;
}

.store-featured-service {
  padding-bottom: 50px;
}

.featured-service-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
}

.featured-service-box {
  border: 1px dashed #e8e8e8;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 18%;
  cursor: pointer;
}

.service-icon {
  width: 75px;
  height: 75px;
  border-radius: 100px;
  background: #f7f7f7;
  line-height: 75px;
  color: #2089fe;
  font-size: 20px;
  font-weight: 600;
  margin: 0px auto;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.featured-service-box h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.featured-service-box span {
  color: #a2a2a2;
  font-size: 12px;
  display: block;
  padding-top: 5px;
}
