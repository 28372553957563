@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap);
body {
  background-color: #ffffff!important;
  margin: 0;
  font-family: 'Montserrat', sans-serif!important;
  font-size: 14px!important;
}

.navbar-light .navbar-brand img {
    max-width: 180px;
}

.header {
    width: 100%;
    background: #fff;
    position: relative;
    z-index: 9;
    box-shadow: 0px 0px 10px #00000014;
}

.header .navbar {
    justify-content: space-between;
    align-items: center;
    padding: .5rem 0rem;
}


.btn-primary:hover, .btn-primary:focus {
    color: #fff;
    background-color: #373373;
    border-color: #373373;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #373373;
    border-color: #373373;
}




.author__access_area ul {
    margin: 0;
}

.btn-gradient.btn-gradient-two:hover, .btn-gradient.btn-gradient-two:focus {
    color: #fff;
}

.author__access_area ul li > span {
    color: #777;
    display: inline-block;
    padding: 0 10px;
}


 .author__access_area ul li .access-link {
    color: #777;
    cursor: pointer;
    font-weight: 500;
}



.navbar-light .navbar-nav .nav-link {
    color: #777!important;
    font-weight: 500;
    margin-right: 40px;
    font-size: 15px;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #f1454f!important;
}

.author__access_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
}

.author__access_area ul li .access-link:hover {
    text-decoration: none;
    color: #018cce;
}


.form-control {
    font-size: 14px!important;
}

.toggle img {
    max-width: 34px;
}

.p-name h5 {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    padding-bottom: 2px;
}

.my-profile-menu .nav-link {
    padding: 0;
    font-size: 11px;
    cursor: pointer;
}

.p-pic img {
    width: 30px;
    height: 30px;
    border-radius: 5px;
}

.my-profile-menu {
    display: flex;
    align-items: center;
}

.my-profile-menu .dropdown-menu {
    min-width: 8rem;
}

.my-profile-menu .dropdown-item {
    font-size: 12px;
    padding: 5px 10px;
    color: #555;
}

.my-profile-menu .dropdown-item:active {
    background: #f8f9fa;
}

.p-pic {
    padding-right: 5px;
}

.header-left {
    display: flex;
    align-items: center;
    width: 40%;
}

.header-right {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
}

.toggle {
    margin-right: 30px;
    cursor: pointer;
}

.search-container .form-control {
    font-size: 14px!important;
    border: none;
    max-width: 250px;
    width: 100%!important;
    margin: 0!important;
}

.search-container .form-control:focus{
    outline: none;
    box-shadow: none;
}

.search-container .dropdown .btn {
    background: transparent;
    color: #777;
    font-size: 14px;
    border: none;
    width: 100%;
    text-align: left;
    border-right: 1px solid #dadada;
    border-radius: 0;
    padding: 0px 15px;
}

.search-container {
    width: 70%;
}

.search-container .dropdown .btn:hover, .search-container .dropdown .btn:focus, .search-container .show>.btn-primary.dropdown-toggle, .search-container .btn-primary:not(:disabled):not(.disabled):active, .search-container .show>.btn-primary.dropdown-toggle{
    border-right: 1px solid #dadada!important;
}

.search-container .btn, .search-container .btn:hover {
    background: transparent;
    color: #777;
    border: none;
}

.search-container .btn:focus {
    box-shadow: none!important;
}

.search-container .form-inline {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    justify-content: space-between;
    box-shadow: 0px 3px 8px #0000000d;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    background: transparent!important;
    color: #777!important;
    border: none!important;
}


.search-container .dropdown {
    max-width: 150px;
    width: 100%;
}

.search-container .dropdown-item{
    font-size: 12px;
    color: #777;
}


.search-container .dropdown-toggle::after{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAFVIUVZAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NkM1MjFFOEEwNkU3MTFFQkIyREVDNjdDMEJGRTRFN0IiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NkM1MjFFOEIwNkU3MTFFQkIyREVDNjdDMEJGRTRFN0IiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2QzUyMUU4ODA2RTcxMUVCQjJERUM2N0MwQkZFNEU3QiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2QzUyMUU4OTA2RTcxMUVCQjJERUM2N0MwQkZFNEU3QiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqNV/2cAAADUSURBVHjaYoyJiWEAAYAAYoQxAAIIxogCYhYgXgQQQHAZEGACYhEomwUggEAyokCGKxAvY0CAOCBeDlL5GiohDMSNMPOA+DdAAIF0ghT8Y0AFfCDBMCAWQxLUAGIrkMQKIHYCYgkg1gRieSDewQRVBZJ0AGI1IN4JEgAIMJAdQkDaEYgPA/ErBtxAHYjNgHg/yIXvgPgQEDtD/bsXiF8iKQZZbQo1cDkQ/2GBSryGOkMYGiYgzU+AWAGIX0CD4g/MFBY0J7yFagY5WQqIjwLxX3R3AgBOQibSMn9j/AAAAABJRU5ErkJggg==);
    width: 13px;
    height: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    position: absolute;
    right: 15px;
    top: 7px;
    margin: 0;
}

/********** Sidebar *********/


.sidebar-wrapper {
    width: 60vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -61vw;
    z-index: 9999;
    padding: 55px;
    background: #ffffff;
    transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    overflow-y: auto;
    box-shadow: 0px 10px 20px #0000005e;
}

.sidebar-wrapper.expand {
    left: -1px;
}

.sidebar-menu {
    position: relative;
    padding: 50px 50px;
}

.sidebar-menu ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}



.close-icon {
    position: absolute;
    right: -20px;
    top: -20px;
    cursor: pointer;
}

.close-icon img {
    width: 30px;
    opacity: 0;
    transition: all 0.5s;
}

.close-icon.show img {
    width: 30px;
    opacity: 1;
}

.sidebar-menu ul li a:hover {
    color: #018ccf;
    text-decoration: none;
}

.side-nav-content ul.main-navigation {
    margin: -45px 0;
}

.side-nav-content ul.main-navigation li {
    margin: 45px 0;
}

.side-nav-content ul.main-navigation li a {
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}

.side-nav-content .axil-contact-info-inner h5.title, .side-nav-content .axil-contact-info-inner span.title {
    font-size: 18px;
    line-height: 1;
    color: #333333;
    font-weight: 500;
    display: block;
    margin-bottom: 15px;
    text-align: left;
    padding-bottom: 0;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 4px;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info .address p a {
    color: #757589;
    text-decoration: none;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info .address p i {
    padding-right: 14px;
    font-size: 14px;
    color: #333333;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info + .axil-contact-info {
    margin-top: 30px;
}

ul.social-share {
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
    margin: -10px;
    flex-wrap: wrap;
}

ul.social-share li {
    margin: 10px;
}

ul.social-share.style-rounded li a {
    width: 32px;
    display: block;
    height: 32px;
    line-height: 32px;
    border-radius: 100%;
    background: #333333;
    text-align: center;
    color: #757589;
    text-decoration: none;
    font-size: 15px;
}

ul.social-share.style-rounded li a i {
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info .address + .address {
    margin-top: 30px;
}
address{
    margin-bottom: 15px;
}


footer {
    position: relative;
}
.footer-social .fa {
    font: normal normal normal 20px/1 FontAwesome;
}
.light-layout-white {
    background-color: #ffffff;
}
.light-layout {
    background-color: #f9f9f9;
}

.border-section {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.small-section {
    padding-top: 35px;
    padding-bottom: 35px;
}
.border-top-0 {
    border-top: 0!important;
}

.footer-light .subscribe {
    display: flex;
    height: 100%;
    align-items: center;
    border-right: 1px solid #ddd;
    text-align: left;
}

.subscribe h4 {
    color: #222;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
}
.footer-light .subscribe p {
    line-height: 1;
    letter-spacing: .03em;
    margin-bottom: 0;
    font-size: 14px;
    color: #bdbdbd;
}

.footer-light .subscribe-form {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
}

.subscribe-form .form-group {
    margin-bottom: 0;
}

.subscribe-form .form-control {
    padding: 13px;
    width: 215px;
    border-radius: 0;
    border: 1px solid #eee;
    vertical-align: middle;
    display: inline-block;
}

.btn-solid {
    padding: 13px 29px;
    color: #fff!important;
    letter-spacing: .05em;
    border: 2px solid #f1454f;
    background-image: linear-gradient(30deg,#f1454f 50%,transparent 0);
    background-size: 540px;
    background-repeat: no-repeat;
    background-position: 0;

}

.section-b-space {
    padding-bottom: 20px;
    padding-top: 50px;
}

.footer-theme .footer-logo {
    margin-bottom: 18px;
}
footer p {
    line-height: 32px;
    letter-spacing: .03em;
    margin-bottom: 0;
    color:#bdbdbd;
}
.footer-social, .social-white {
    padding-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-logo img {
    max-width: 150px;
}

.footer-social li, .social-white li {
    display: inline-block;
    padding-right: 15px;
    padding-left: 15px;
}


.footer-social ul, .social-white ul {
    padding-left: 0;
    margin-bottom: 0;
}

.footer-social i, .social-white i {
    font-size: 20px;
    color: #bdbdbd;
    transition: .5s ease;
}

.footer-theme .sub-title h4 {
    color: #222;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
}

.footer-theme .sub-title li, .footer-theme .sub-title li a {
    position: relative;
    color: #bdbdbd;
}
.footer-theme .sub-title li {
    display: inherit;
    padding-top: 13px;
    text-transform: capitalize;
    font-size: 14px;
}

.footer-theme .sub-title li a:before {
    position: absolute;
    top: 20px;
    content: "";
    height: 2px;
    width: 0;
    background-color: #ff4c3b;
    transition: .5s ease;
}

.footer-theme .sub-title .contact-list li {
    position: relative;
    padding-left: 25px;
    line-height: 20px;
}

.footer-theme .sub-title .contact-list i {
    top: 17px;
}
.footer-theme .sub-title .contact-list i {
    position: absolute;
    left: 0;
    top: 17px;
}

.sub-footer ul li a:hover {
    text-decoration: none;
    color: #018ccf;
}

.sub-footer p {
    color: #bdbdbd;
    margin-bottom: 0;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    
}


.sub-footer .payment-card-bottom {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.sub-footer .payment-card-bottom li {
    padding-left: 7px;
    padding-right: 7px;
    display: inline-block;
}

dl, ol, ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}


.footer-theme .sub-title li a:hover {
    color:#018ccf;
}

.footer-social li a:hover i{
    color:#018ccf;
}

.sub-title {
    text-align: center;
}

.footer-title h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 25px;
    color: #fff;
}

.footer-contant p {
    line-height: 22px;
    margin-bottom: 0;
    color: #bdbdbd;
    font-size: 14px;
    max-width: 200px;
    margin: 0px auto;
}

.footer-light .container {
    max-width: 998px;
}

ul.contact-list li a, ul.contact-list li {
    font-size: 14px;
    color: #bdbdbd;
}

ul.contact-list li {
    padding-bottom: 3px;
}

.sub-footer ul {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-footer ul li {
    padding: 0px 15px;
}

.sub-footer ul li a {
    color: #bdbdbd;
    font-size: 14px;
}

.footer-dark {
    background: #131111;
}
.home-banner{
    background: #313131 url(/static/media/home-banner.01c69c32.jpg);
    background-repeat: no-repeat;
    padding: 175px 0px;
    background-size: cover;
    width: 100%;
}

.banner-content {
    background: rgb(0 0 0 / 0.32);
    padding: 50px;
    max-width: 750px;
    border-radius: 2px;
}

.banner-content h2 {
    color: #fff;
    font-weight: 700;
    font-size: 48px;
    margin: 0;
    padding-bottom: 15px;
}

.banner-content p {
    color: #fff;
    max-width: 480px;
    font-size: 16px;
}


.home-category {
    padding: 80px 0px;
}

.scrollable-category .col-md-3 {
    flex: 0 0 20%;
    max-width: 20%;
}

.category-thumb img {
    width: 100%;
    height: 125px;
    object-fit: cover;
}

.category-info {
    border: 1px solid #efefef;
    padding: 15px 20px;
}

.category-info h2 {
    font-size: 16px;
    margin: 0;
    padding-bottom: 2px;
    font-weight: 600;
}

.category-info a {
    color: #777;
    font-size: 12px;
    font-weight: 500;
}

.category-info a:hover {
    text-decoration: none;
}

.category-box {
    position: relative;
    margin-bottom: 30px;
}



.title {
    text-align: center;
    margin: 0;
    padding-bottom: 50px;
    font-weight: 400;
    font-size: 30px;
}

.title span {
    font-weight: 700;
}

.scrollable-category {
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollable-category::-webkit-scrollbar {
    display: none;
}



.featured-store{
    padding: 80px 0px;
    background: #fff;
}

.featured-store-thumb img {
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.featured-store-info {
    box-shadow: 0px 0px 10px #00000014;
    padding: 15px 20px;
    background: #fff;
}

.featured-store-info h2 {
    font-size: 17px;
    margin: 0;
    padding-bottom: 5px;
    font-weight: 600;
}

.featured-store-info a {
    color: #777;
    font-size: 12px;
    font-weight: 500;
}

.featured-store-info a:hover {
    text-decoration: none;
}

.featured-store-box {
    position: relative;
    margin-bottom: 30px;
}

.featured-store-info p {
    font-size: 13px;
    margin: 0;
    padding-top: 5px;
}


.featured-store .title {
    text-align: left;
}

.featured-rating i {
    color: #ffd335;
}

.featured-rating span {
    font-weight: 600;
}


.testimonial-box {
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
}

.testimonial-thumb img {
    width: 80px;
    height: 80px;
    margin: 0px auto;
    display: block;
    border-radius: 100%;
    border: 5px solid #ffffff;
    box-shadow: 0px 0px 10px #0000001a;
}

.testimonial-info h2 {
    text-align: center;
    font-size: 18px;
    margin: 0;
    padding: 15px 0px 5px;
}

.testimonial-info {
    text-align: center;
}

.testimonial-info span {
    color: #777;
    font-size: 12px;
    padding-bottom: 19px;
    display: block;
}

.testimonial-info p {
    color: #777;
    font-size: 14px;
    margin: 0;
}

.scrollable-testimonial {
    height: 556px;
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollable-testimonial::-webkit-scrollbar {
    display: none;
}

.scrollable-testimonial .testimonial-box:last-child {
    margin: 0;
}
.login {
    background: #f9f9f9;
}

.login-section {
    padding: 80px 0px;
}


.login-form {
    padding: 50px 40px;
    max-width: 500px;
    margin: 0px auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px;
}


.head-logo img {
    max-width: 100px;
    margin: 0px auto;
    display: block;
    border: 2px solid#018bcf;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 20px;
}

.login-form h1 {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    color: #333;
}

.login-form h1 span {
    color:#018bcf;
}

.login-box {
    padding-top: 30px;
    max-width: 400px;
    margin: 0px auto;
}

.login-box .form-group .form-control {
    height: auto;
    padding: 12px 20px;
    background: transparent;
    border: 1px solid #dadada;
    border-radius: 4px;
    font-size: 14px;
}

.login-box .form-group .form-control:focus{
    box-shadow: none;
    background: transparent;
}
.login-box input:-internal-autofill-selected{
    background: transparent!important;
}

.form-label {
    font-size: 14px;
    color: #8091a1;
    font-weight: 600;

}

.form-group {
    margin-bottom: 35px;
}

.l-btn.btn.btn-primary {
    padding: 12px 40px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border-radius: 4px;
    background: #018bcf;
    border: none;
    transition: all 0.5s;
    width: 100%;

}

.l-btn.btn.btn-primary:hover, .l-btn.btn.btn-primary:focus, .l-btn.btn.btn-primary:active {
    background: #018bcf;
    box-shadow: none;
    border: none;
}

.login-button {
    text-align: center;
}

.login-box p {
    text-align: center;
    padding-top: 30px;
    font-size: 14px;
    color: rgb(160, 160, 160);
    margin: 0;
    font-weight: 500;

}

.signup-link {
    color:#018bcf;
    text-decoration: underline;
}

.signup-link:hover{
    color: #c5c5c5;
}

.forget-password{
    text-align: right;
}

.forget-link {
    color: #018bcf;
    font-size: 14px;
    font-weight: 500;
}

.forget-link:hover {
    color: #c5c5c5;
    text-decoration:none;
}

.forget-password {
    padding-bottom: 30px;
    margin-top: -10px;
}

.login-popup .modal-header {
    border-bottom: none;
    padding-bottom: 0;
}

.password-show {
    position: relative;
}

.password-show i {
    position: absolute;
    right: 12px;
    bottom: 16px;
    cursor: pointer;
}

.login-popup .form-label {
    font-size: 14px;
    color: #333;
    font-weight: 600;
}


.login-popup .modal-header .close:focus {
    outline: none;
}

.login-popup .close {
    color: #999;
    text-shadow: none;
    font-weight: 400;
    font-size: 30px;
}

.login-popup .close:hover {
    color: #777;
}

.modal-backdrop {
    background-color: #352929!important;
}
.signup {
  background: #f9f9f9;
}

.register-section {
  padding: 80px 0px;
}
.register-form {
  padding: 50px 40px;
  max-width: 600px;
  margin: 0px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px;
}

.head-logo img {
  max-width: 100px;
  margin: 0px auto;
  display: block;
  border: 2px solid #018bcf;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.register-form h1 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  max-width: 500px;
  margin: 0px auto;
  color: #333;
}

.register-form h1 span {
  color: #018bcf;
}

.register-form .rigister-tab {
  margin-top: 40px;
}

.rigister-tab p {
  text-align: center;
  padding-top: 30px;
  font-size: 14px;
  color: rgb(160, 160, 160);
  margin: 0;
  font-weight: 500;
}

.terms-link {
  color: #018bcf;
  text-decoration: underline;
}

.terms-link:hover {
  color: #c5c5c5;
}

.dis-show .form-check {
  display: inline-block;
}

.dis-show p {
  font-size: 14px;
  color: rgb(160, 160, 160);
  margin: 0;
  font-weight: 500;
  padding: 0 !important;
}

.dis-show {
  display: flex;
  align-items: center;
}
.s-btn.btn.btn-primary {
  padding: 12px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 4px;
  background: #018bcf;
  border: none;
  transition: all 0.5s;
  width: 100%;
  margin-top: 20px;
}

.s-btn.btn.btn-primary:hover,
.s-btn.btn.btn-primary:focus,
.s-btn.btn.btn-primary:active {
  background: #018bcf;
  box-shadow: none;
  border: none;
}

.register-form .form-group .form-control {
  height: auto;
  padding: 12px 20px;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 4px;
  font-size: 14px;
}

.register-form .form-group .form-control:focus {
  box-shadow: none;
  background: transparent;
}
.register-form input::-internal-autofill-selected {
  background: transparent !important;
}

.signup-popup .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}



 .form-label {
    font-size: 14px;
    color: #8091a1;
    font-weight: 600;
}

.signup-popup .modal-header .close:focus {
  outline: none;
}

.signup-popup .close {
  color: #999;
  text-shadow: none;
  font-weight: 400;
  font-size: 30px;
}

.signup-popup .close:hover {
  color: #777;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border: 1px solid #dadada;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.error {
  position: relative;
  font-weight: 400 !important;
  font-size: 10px !important;
  color: #f91c1c !important;
  text-transform: capitalize;
  text-align: left !important;
  padding: 0 !important;
  margin: 0px !important;
}

.number-fields {
  display: flex;
  align-items: center;
}

.number-fields .dropdown .btn {
  height: auto;
  padding: 11px 20px;
  background: transparent;
  border: 1px solid #a58d8d !important;
  border-radius: 4px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  line-height: 23px;
  color: #777;
}

.number-fields .form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.number-fields .dropdown .btn:focus {
  border: 1px solid #dadada;
  border-right: 0;
  box-shadow: none;
}

.number-fields .btn-primary:not(:disabled):not(.disabled):active {
  border: 1px solid #dadada !important;
  border-right: 0 !important;
  box-shadow: none;
}

.number-fields .show > .btn-primary.dropdown-toggle {
  border: 1px solid #dadada !important;
  border-right: 0 !important;
  box-shadow: none !important;
}

.number-fields .dropdown-item {
  font-size: 14px;
  color: #777;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.forget-section {
    padding: 50px 0px;
    background: #f9f9f9;
}

.forget-form {
    background: #fff;
    padding: 50px 30px;
    max-width: 500px;
    margin: 0px auto;
    margin-bottom: 30px;
    border-radius: 8px;
    box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px;
}

.forget-form h1 {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: #333333;
}

.forget-form h1 span {
    color: #018bcf;
}


.form-group .form-control {
    height: 40px;
    font-size: 14px;
    padding: 5px 15px;
}

.forget-form p {
    text-align: center;
    font-size: 14px;
    color: #777;
    margin-bottom: 50px;
    margin-top: 15px;
}

.forget-box {
    max-width: 400px;
    margin: 0px auto;
}

.forget-form .reset-btn {
    background: #018bcf;
    border: none;
    transition: all 0.5s;
    padding: 12px 40px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    border-radius: 4px;
    margin: 0px auto;
    display: block;
    width: 100%;
}

.reset-box {
    max-width: 400px;
    margin: 50px auto;
    margin-bottom: 0;
}

.btn-primary:not(:disabled):not(.disabled):active {
    background: #018bcf;
    box-shadow: none;
    border: none;
}

.btn-primary:hover {
    color: #fff;
    background: #018bcf;
    box-shadow: none;
    border: none;
}
.store-listing {
  position: relative;
}

.store-listing-cover {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 80px 0px;
  width: 100%;
}

.store-listing-cover:before {
  content: '';
  background: #000;
  opacity: 0.7;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.store-listing-cover h2 {
  position: relative;
  font-weight: 700;
  font-size: 35px;
  color: #fff;
  text-align: center;
}

.store-listing-filter {
  margin: 50px 0px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}

.store-filter-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.store-search-result p {
  margin: 0;
  color: #999;
  font-size: 16px;
  font-weight: 500;
}

.store-filter-box .switch {
  margin-left: 10px;
}

.store-filter-box .dropdown {
  margin-left: 10px;
}

.store-filter-box .dropdown .btn {
  background: transparent;
  border: 1px solid #cac9c9;
  color: #777;
  font-size: 15px;
}

.store-filter-box .dropdown .btn:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.store-filter-box .dropdown .btn-primary:not(:disabled):not(.disabled):active {
  border: 1px solid #cac9c9 !important;
}

.store-filter-box .show > .btn-primary.dropdown-toggle {
  border: 1px solid #cac9c9 !important;
}

.store-filter-box .dropdown-item {
  font-size: 14px;
}

.store-listing-view {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

.store-list {
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.store-image img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.store-image {
  width: 40%;
  position: relative;
}

.store-detail {
  width: 60%;
  padding: 10px 20px;
}

.store-favorite {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #ffffffb8;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 38px;
  border-radius: 100%;
  box-shadow: 0px 0px 5px 0px #0000001f;
}

.store-favorite i {
  color: #ff2424;
  font-size: 16px;
}

.store-detail h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 10px;
}

.store-detail ul li {
  padding: 5px 0px;
  color: #777;
  font-size: 13px;
}

.store-rating i {
  color: #ffd437;
  font-size: 16px;
  margin-right: 2px;
}

.store-detail ul {
  margin: 0;
}

.store-rating {
  padding-top: 10px;
}

.store-rating span {
  background: #4caf50;
  color: #fff;
  padding: 5px 7px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}

.store-pagination {
  text-align: center;
}

.store-pagination .pagination {
  justify-content: center;
  padding-bottom: 50px;
}

.store-pagination .page-link {
  color: #777;
  background-color: #dee2e6;
  border: 1px solid #dee2e6;
  width: 40px;
  height: 40px;
  line-height: 24px;
  margin: 0px 5px;
  border-radius: 100%;
}

.store-pagination .page-item.active .page-link {
  width: 40px;
  height: 40px;
  line-height: 24px;
  margin: 0px 5px;
  border-radius: 100%;
}

.store-pagination .page-item:first-child .page-link {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}

.store-pagination .page-item:last-child .page-link {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}

.store-pagination .page-link:hover {
  color: #777;
}

.store-detail ul li i {
  color: #ff2424;
  font-size: 16px;
  margin-right: 5px;
  vertical-align: middle;
}

.store-location {
  margin-bottom: 50px;
  display: none;
}

.store-location.show {
  display: block;
}

/******************************** Store Detail Page *******************************/

.store-slider-container {
  overflow: hidden;
}

.store-slider img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.store-slider img:focus {
  outline: none;
}

.opening-hours {
  overflow: hidden;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 30px;
  transform: translate3d(0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
  margin-top: 35px;
}

.opening-hours h3 {
  width: 100%;
  font-size: 20px;
  line-height: 40px;
  padding: 0 0 20px;
  margin: 5px 0 20px 0;
  display: block;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}

.opening-hours h3 i {
  margin-right: 10px;
  color: #fff;
  width: 40px;
  height: 40px;
  background: #018cce;
  border-radius: 50%;
  position: relative;
  top: 1px;
  text-align: center;
  line-height: 40px;
}

.opening-hours ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.opening-hours ul li {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #66676b;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 6px 0;
  transition: 0.2s;
  cursor: default;
}

.opening-hours ul li span {
  float: right;
  font-weight: 400;
  font-size: 12px;
}

.store-detail-info {
  background: #f9f9f9;
  padding: 20px 25px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.store-about {
  padding: 50px 0px;
}

.about-store {
  padding-top: 50px;
}

.store-detail-info h2 {
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #323232;
}

.store-detail-info .store-detail {
  padding: 0;
  width: 100%;
}

.store-detail-info .store-detail ul li {
  font-size: 14px;
  font-weight: 500;
}

.book-now .btn {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 20px;
  background: #018cce;
  border: none;
}

.heading-title {
  font-size: 22px;
  font-weight: 600;
  position: relative;
  display: block;
  padding-bottom: 30px;
  margin-bottom: 10px;
}

.heading-title::after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 20px;
  height: 2px;
  width: 40px;
  background-color: #018cce;
}

.about-store p {
  font-size: 15px;
  line-height: 27px;
  color: #777;
}

.about-store ul li {
  border: 1px solid #e0e0e0;
  color: #555;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 8px;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
}

.near-venue {
  padding: 50px 0px;
}

.opening-hours .form-control {
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  outline: none;
  font-size: 15px;
  color: #808080;
  margin: 0 0 16px 0;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03);
  font-weight: 400;
  opacity: 1;
  border-radius: 4px;
}

.opening-hours textarea {
  height: auto;
  line-height: 24px;
  padding: 15px;
  min-height: 130px;
  transition: none !important;
  min-width: 100%;
}

.opening-hours .btn.btn-primary {
  text-align: center;
  margin: 0 auto;
  display: block;
  border: 0;
  line-height: 28px;
  height: auto;
  padding: 10px 20px;
}

.store-review .opening-hours {
  margin-top: 0;
}

.store-review {
  padding: 50px 0px;
}

.comments ul {
  padding-left: 0;
}

.comments ul li {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 20px;
  border-radius: 4px;
  display: block;
  width: 100%;
  margin: 30px 0 0 0px;
}

.comments .avatar {
  display: inline-block;
  padding: 0 20px 0 20px;
  float: left;
  position: absolute;
  left: 0px;
}

.comments .avatar img {
  width: 80px;
  height: 80px;
  border: 5px solid rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  object-fit: cover;
}

.comments .utf_comment_content {
  color: #666;
  padding: 0 0 0px 100px;
}

.utf_star_rating_section {
  display: block;
  font-size: 15px;
  float: left;
  position: absolute;
  right: 10px;
  top: 30px;
}

.utf_star_rating_section .star {
  display: inline-block;
  margin: 0;
  padding: 0;
  float: left;
  margin-right: 4px;
  position: relative;
  color: #888;
}

.utf_star_rating_section .star:before {
  font-family: 'FontAwesome';
  content: '\f005';
  display: block;
  color: #ffc600;
}

.utf_by_comment {
  width: 100%;
  padding-bottom: 0;
  padding-top: 0px;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  display: inline-block;
  padding-right: 100px;
}

.utf_by_comment span.date {
  color: #888;
  font-size: 12px;
  font-weight: 400;
  margin: 0px 0 10px 0;
  float: none;
  display: block;
  text-align: left;
}

.utf_by_comment span.date i {
  color: #007bff;
  font-size: 14px;
  margin-right: 2px;
}

.utf_comment_content p {
  padding: 5px 0;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  line-height: 25px;
}

.utf_leave_rating {
  height: 48px;
  float: left;
  background: #fff;
  padding: 12px 15px;
  line-height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  text-align: center;
  vertical-align: middle;
  margin: 0 auto;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03);
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.utf_leave_rating input[type='radio'] {
  display: none;
}

.utf_leave_rating input[type='radio'] {
  width: 15px;
  height: 15px;
  cursor: pointer;
  box-shadow: none;
  padding: 0;
}

.utf_leave_rating label {
  font-size: 24px;
  letter-spacing: 6px;
  color: #dadada;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 0;
}

.utf_leave_rating span {
  margin-right: 10px;
  color: #808080;
  font-size: 14px;
}

.utf_leave_rating label:hover,
.utf_leave_rating label:hover ~ label {
  color: #ffc600 !important;
}

.utf_leave_rating input[type='radio']:checked ~ label {
  color: #ffc600;
}

.store-services {
  padding: 50px 0px;
}

.store-service-tab {
  padding-top: 35px;
}

.store-service-tab-content {
  padding: 40px 0px;
}

.store-service-tab .nav-tabs {
  border: none;
  justify-content: flex-start;
  border-bottom: 2px solid rgb(0 0 0 / 0.1);
  margin-bottom: 0px;
  counter-reset: a;
}

.store-service-tab .nav-tabs .nav-link::before {
  counter-increment: a;
  content: counter(a, decimal-leading-zero);
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 10px;
}

.store-service-tab .nav-tabs .nav-link {
  background: transparent;
  color: #161c23;
  border: none;
  padding: 10px 20px;
  margin: 0px 0px;
  position: relative;
  top: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
}

.store-service-tab .tab-content {
  border: none;
  margin: 0;
  padding: 0;
}

.store-service-tab .nav-tabs .nav-link.active {
  background: transparent !important;
  border: none;
  color: #007bff !important;
  border-bottom: 4px solid #007bff !important;
}

.store-service-tab .nav-tabs .nav-link.active:hover,
.store-service-tab .nav-tabs .nav-link.active:focus {
  border-bottom: 4px solid #007bff !important;
}

.store-service-tab .nav-tabs .nav-link:hover {
  border-bottom: 4px solid transparent;
}

.store-service-tab .nav-tabs .nav-link:hover,
.store-service-tab .nav-tabs .nav-link:focus {
  border: none;
  outline: none;
}

.service-selection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.service-list-view {
  width: 48%;
  display: flex;
  align-items: flex-start;
  padding: 30px 0px;
  border-bottom: 1px solid #ddd;
}

.service-checkbox .form-check {
  padding: 0;
}

.service-detail {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.service-info h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.service-info p {
  color: #999;
  margin: 0;
  padding-top: 2px;
  font-size: 14px;
}

.service-cost h5 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.service-selection .service-list-view:nth-last-child(1),
.service-selection .service-list-view:nth-last-child(2) {
  border: none;
}

.service-checkbox input[type='checkbox'] + label {
  display: block;
  margin: 0;
  cursor: pointer;
  padding: 0;
}

.service-checkbox input[type='checkbox'] {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 2em;
  height: 2em;
  margin: 0;
  cursor: pointer;
}

.service-checkbox input[type='checkbox']:focus {
  outline: none;
}

.service-checkbox input[type='checkbox'] + label:before {
  content: '\2714';
  border: 1px solid #cecece;
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  border-radius: 100%;
  text-align: center;
  line-height: 2em;
}

.service-checkbox input[type='checkbox'] + label:active:before {
  transform: scale(0);
}

.service-checkbox input[type='checkbox']:checked + label:before {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.service-checkbox input[type='checkbox']:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

.service-checkbox input[type='checkbox']:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.slick-prev {
  left: 10px;
  background: #000000a3;
  z-index: 9;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.slick-next {
  right: 10px;
  background: #000000a3;
  z-index: 9;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: #fff;
  outline: 0;
  background: #000000a3;
}

.slick-prev:before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAATCAYAAAHe7Kx4AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NURGREJBNjkxNDY0MTFFQkE2MkNBOTM3Q0Q5NkZBM0QiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NURGREJBNkExNDY0MTFFQkE2MkNBOTM3Q0Q5NkZBM0QiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1REZEQkE2NzE0NjQxMUVCQTYyQ0E5MzdDRDk2RkEzRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1REZEQkE2ODE0NjQxMUVCQTYyQ0E5MzdDRDk2RkEzRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvzH4NgAAADPSURBVHjaYvz//z8DEHgzABlWIA4jVOQ/QACBRBiYQEwwARBAYC4QW6IqYmBgBAggGAeqDijKBJMCMaaDhGBqZgAEEMwYZOPgHNP/EADmg4i5QHwbWQey1pkw1XAHIIHlAAGETfAeE5oASMU2ZDNBYAayRXABmCAITEd3EgjoY3MnCJhhczxcggXJOYxQJ1myoLkTLAEQYOhBh44t/iMHHz4FQDAN3UFYFaC7HKTgDzYF6IEBAivxuRfGmIqkwQqfQoIacFmFoYFQOE6CqQYAGNb6y1nIfDAAAAAASUVORK5CYII=);
  width: 10px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  display: block;
  margin: 0px auto;
}

.slick-next:before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAATCAYAAAHe7Kx4AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NkQwOEVFQjUxNDY0MTFFQjk2RTY4RDREQTM2OTVFMTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NkQwOEVFQjYxNDY0MTFFQjk2RTY4RDREQTM2OTVFMTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RDA4RUVCMzE0NjQxMUVCOTZFNjhENERBMzY5NUUxNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2RDA4RUVCNDE0NjQxMUVCOTZFNjhENERBMzY5NUUxNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvDbuP4AAADOSURBVHjaYvj///89IGZgBBFA8J8JSFgAMSNAADGAREA8CAEQQHAFIDZYBAjMQQRAAMFkwOpmIItMRzEVrBMggECmTvkPAQwwzIDEmQGTRBYEYX1kLWbIRrDADYdgMAAIIJCMN5oRYDNBwBKb7SgSyNpAwBRmEQwwQi21hnsfCm4D8SF07dPQLZqJ7qQZ6E5aju54gACDqQQBC3RJbB6aRkgDugBODbisgmn4A9PAgM9dQLzyP3rco2ErJCdMxWYihgJ0N+JUgC0cJ+FzLwCB/wzMtorhrwAAAABJRU5ErkJggg==);
  width: 10px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  display: block;
  margin: 0px auto;
}

.store-featured-service {
  padding-bottom: 50px;
}

.featured-service-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
}

.featured-service-box {
  border: 1px dashed #e8e8e8;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 18%;
  cursor: pointer;
}

.service-icon {
  width: 75px;
  height: 75px;
  border-radius: 100px;
  background: #f7f7f7;
  line-height: 75px;
  color: #2089fe;
  font-size: 20px;
  font-weight: 600;
  margin: 0px auto;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.featured-service-box h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.featured-service-box span {
  color: #a2a2a2;
  font-size: 12px;
  display: block;
  padding-top: 5px;
}

.admin-view .header,
.admin-view .footer {
  display: none;
}

.admin-wrapper {
  overflow-x: hidden;
  font-family: 'Nunito', sans-serif!important;
}

.admin-view .main_content {
  padding: 0;
}

.header-view {
  text-align: center;
  position: relative;
  z-index: 99;
}

.header-top-box h1 {
  font-size: 30px;
  padding: 5px 0px;
}

.admin-content-view {
  display: flex;
}

.admin-menu {
  min-height: 100vh;
  border-right: 1px solid#262f3e;
  width: 250px;
  background: #262f3e;
  transition: all 0.5s;
}

.admin-content {
  padding: 30px 20px;
}

.admin-container {
  width: calc(100% - 250px);
  background: #f6f7fb;
  transition: all 0.5s;
}

.admin-menu.menu-hide {
  width: 0;
  opacity: 0;
}

.admin-container.wrapper-expand {
  width: 100%;
}

.menu-list ul {
  margin: 0;
  padding: 0;
}

.menu-list ul a {
  padding: 18px 25px;
  display: block;
  color: #8091a1;
  text-decoration: none;
  transition: all 0.5s;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
}

.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background: #fff;
  min-height: 60px;
  box-shadow: 0px 0px 15px #33333312;
}

.menu-logo {
  width: 250px;
  text-align: center;
  background:#262f3e;
  padding: 11px 10px;
  border-bottom: 1px solid #2d3646;
}

.menu-logo h2 {
  font-weight: 600;
  font-size: 25px;
  color: #fff;
  margin: 0;
}

.admin-info img {
  width: 35px;
  height: 35px;
  object-fit: contain;
  border-radius: 50px;
  margin-right: 0px;
}

.admin-info {
  display: flex;
  align-items: center;
}

.menu-logo img {
  max-width: 150px;
  width: 100%;
}

.menu-toggle img {
  width: 32px;
  opacity: 0.9;
  transition: all 0.5s;
  cursor: pointer;
}

.menu-toggle img:hover {
  opacity: 1;
}

.menu-list {
  padding-top: 40px;
}

.menu-list ul a:hover {
  color: #fff;
  background: #262f3e;
}

.menu-list ul a.active {
  color: #ddd;
}

.admin-info h3 {
  margin: 0;
  font-size: 14px;
  color: #212529;
  font-weight: 600;
}

.menu-list ul a i {
  margin-right: 10px;
  font-size: 20px;
  vertical-align: bottom;
}

.dashboard .card {
  margin-bottom: 15px;
  margin-top: 15px;
  border-color: #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.dashboard .h4 {
  color: #898b8a;
}

.dashboard .text-muted {
  color: #abb2b8!important;
}

.menu-toggle a {
  color: #555;
  font-size: 20px;
}

.admin-title {
  margin: 0;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: #555;
}

.admin-title a {
  font-size: 14px;
  font-weight: 500;
  color:#a5a5a5;
}

.admin-title a:hover {
  text-decoration: none;
  color: #262f3e;
}

.font-weight-600 {
  font-weight: 600;
}

.dashboard .fa {
  font-size: 40px;
  opacity: 0.2;
}

.dashboard .card-body {
  cursor: pointer;
  padding: 30px 20px;
}

.dashboard .card-body .fa {
  transition: all 0.5s;
}

.dashboard .card-body:hover .fa {
  opacity: 1;
  color: #262f3e !important;
}

.user-list .table-responsive > .table-bordered .btn {
  background: #373373;
  border: none;
  padding: 5px 20px;
  font-size: 14px;
  min-width: 90px;
}

.user-list .table td,
.table th {
  padding: 15px 20px;
  vertical-align: middle;
}

.user-list .table td p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  margin: 0;
}

.user-list .table-responsive > .table-bordered {
  border: 0;
  background: #fff;
}

.user-list .table td {
  font-size: 14px;
}

.user-list .table td a {
  color: #478adc;
}

.action-buttons .btn {
  margin: 0px 5px;
}

.block-btn {
  background: #f1454f !important;
}

.approve-btn {
  background: #37a864 !important;
}

.edit-btn {
  background: #6d6d6d !important;
}

.admin-search {
  text-align: right;
  padding-bottom: 25px;
}

.admin-search .form-inline {
  justify-content: flex-end;
}

.admin-search .btn {
  background: #555555;
  border: none;
  padding: 7px 15px;
  font-size: 14px;
}

.admin-search .form-control {
  font-size: 13px;
  padding: 8px 10px;
  height: auto;
}

.admin-login {
  background: #f9f9f9;
  padding: 100px 0px;
  min-height: 100vh;
}

.admin-login-box {
  padding: 50px 40px;
  max-width: 500px;
  margin: 0px auto;
  background: #fff;
}

.admin-login-box .form-group .form-control {
  height: auto;
  padding: 10px 20px;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 4px;
  font-size: 14px;
}

.admin-login-box .form-group .form-control:focus {
  box-shadow: none;
  background: transparent;
}
.admin-login-box input:-internal-autofill-selected {
  background: transparent !important;
}

.admin-login-box h2 {
  font-size: 35px;
  text-align: center;
  font-weight: 600;
  color: #333;
  padding-bottom: 20px;
}

.admin-detail {
  display: flex;
  align-items: flex-start;
  padding: 20px 15px;
}

.admin-pic img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 3px solid #fff;
}

.admin-name span {
  font-size: 12px;
  color: #d0cdcd;
}

.admin-name h4 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 5px;
}

.admin-name {
  padding-left: 20px;
  padding-top: 5px;
}

.menu-list ul h5 {
  font-size: 12px;
  text-transform: uppercase;
  padding: 0px 25px;
  margin-top: 20px;
  color: #fff;
  margin-bottom: 10px;
}

ul.sub-menu {
  background: #5f5f5f;
  display: none;
}

ul.sub-menu li a {
  font-size: 13px;
  padding-left: 50px;
}

ul.sub-menu li a i {
  margin-right: 2px;
}

.menu-list ul li {
  position: relative;
}

.menu-list ul > li.has-menu > a:after {
  content: '\f107';
  font-family: FontAwesome;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: all 0.5s;
}

ul.sub-menu.menu-open {
  display: block;
}

.menu-list ul > li.has-menu.menu-expand > a:after {
  transform: rotate(180deg);
}

.admin-info .dropdown-toggle {
  background: transparent;
  font-size: 12px;
  color: #333;
  border: none;
}

.admin-info .dropdown-toggle:hover,
.admin-info .btn-primary:not(:disabled):not(.disabled):active:focus,
.admin-info .dropdown-toggle:focus,
.admin-info .show > .btn-primary.dropdown-toggle:focus {
  background: transparent;
  font-size: 12px;
  color: #333;
  border: none;
  box-shadow: none;
}

.admin-info .btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent;
  border-color: transparent;
  color: #333;
}

.admin-info .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  color: #333;
}

.admin-info .dropdown-item {
  font-size: 13px;
  padding: 8px 20px;
  font-weight: 600;
  color: #999;
}

.admin-info .dropdown-menu {
  min-width: 6rem;
  left: -5px !important;
}

.admin-search .form-label {
  padding-right: 20px;
}

.admin-search select.form-control {
  max-width: 150px;
  width: 100%;
}

.add-distributor {
  padding: 50px;
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 50px;
}

.add-distributor .form-group {
  margin-bottom: 20px;
}

.add-distributor .admin-title {
  padding-bottom: 30px;
}

.distributor-button .d-btn {
  background: #37a864;
  border: none;
  padding: 10px 30px;
  font-size: 15px;
}

.distributor-form label {
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

.user-list .tab-content {
  padding: 30px;
  background: #fff;
  border: 1px solid #dee2e6;
  margin-top: -1px;
  padding-top: 50px;
}

.user-list .nav-tabs .nav-link {
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  width: 50%;
  text-align: center;
}

.user-list .nav-tabs .nav-link:focus,
.user-list .nav-tabs .nav-link:hover {
  outline: none;
}

.font-weight-700 {
  font-weight: 700!important;
}


.category-popup {
  font-family: 'Nunito', sans-serif!important;
}


.admin-content-view .form-control::-webkit-input-placeholder {
  color: #8091a1;
}


.admin-content-view .form-control:-ms-input-placeholder {
  color: #8091a1;
}


.admin-content-view .form-control::-ms-input-placeholder {
  color: #8091a1;
}


.admin-content-view .form-control, .admin-content-view .form-control::placeholder {
  color: #8091a1;
}


.service-available {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.service-available label {
  width: 100%;
}

.service-available .form-check {
  margin-right: 20px;
  color: #8091a1;
}


/************ Services List ****************/

.partner-services-content {
  padding: 20px 0px;
}

.header-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-buttons .btn {
  margin-left: 10px;
  border: 1px solid transparent;
  min-width: 100px;
  font-weight: 500;
  font-size: 14px;
  background: #262f3e;
}

.header-buttons .btn:hover {
  border: 1px solid transparent;
  background: #262f3e;
}

.header-buttons .dropdown-toggle::after {
  display: none;
}

.light-btn .btn {
  background: #ffffff;
  color: #333;
  box-shadow: 0 3px 5px 0 rgba(164, 173, 186, 0.25);
}

.header-buttons .dropdown-item {
  font-size: 14px;
  font-weight: 500;
  color: #555;
}

.header-buttons .dropdown a {
  display: block;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  color: #555;
}

.dropdown a:hover {
  text-decoration: none;
}

.dark-btn .btn-primary:not(:disabled):not(.disabled).active,
.dark-btn .btn-primary:not(:disabled):not(.disabled):active,
.dark-btn.show > .btn-primary.dropdown-toggle {
  background: #262f3e !important;
  color: #fff !important;
  border: 1px solid #262f3e !important;
}

.dark-btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.dark-btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.dark-btn.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.light-btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.light-btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.light-btn.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  background: #ffffff;
  color: #333;
  box-shadow: 0 3px 5px 0 rgba(164, 173, 186, 0.25);
}

.service-list {
  position: relative;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
}

.service-list h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 0;
  text-transform: uppercase;
  color: #666;
  letter-spacing: 0.5px;
}

.service-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
}

.service-list-header [class*='service-'] {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  color: #8091a1;
  font-weight: 600;
}

.service-list-header .service-discount {
  text-decoration: inherit;
}


.service-list-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
  padding: 15px 10px;
  border: 1px solid #eaeaea;
  margin-top: -1px;
  cursor: pointer;
}

.service-list-items [class*='service-'] {
  padding: 10px;
  font-weight: 500;
  text-align: left;
}

.service-name {
  font-weight: 600 !important;
  font-size: 15px;
  width: 31%;
  text-align: left !important;
}

.service-name span {
  display: block;
  font-size: 12px;
  color: #999;
  font-weight: 500 !important;
}

.service-discount {
  text-decoration: line-through;
  color: #999;
  width: 10%;
}

.service-hour {
  width: 15%;
}

.service-price {
  font-size: 16px;
  font-weight: 700 !important;
  width: 10%;
}

.service-extra{
  width: 17%;
}

.add-category textarea {
  height: 120px !important;
}

.category-popup .modal-header .close {
  position: absolute;
  right: 15px;
  top: 13px;
  font-size: 35px;
  font-weight: 300;
  padding: 15px;
}

.category-popup .modal-title {
  font-weight: 700;
  font-size: 20px;
  color: #333;
}

.category-popup .modal-header {
  justify-content: center;
}

.category-popup .modal-body {
  padding: 30px;
}

.category-popup .modal-header .close:focus {
  outline: none;
  box-shadow: none;
}

.add-category .l-btn.btn.btn-primary {
  background:#262f3e;
  padding: 10px 40px;
  font-size: 15px;
}

.add-category .btn-primary:not(:disabled):not(.disabled):active {
  background: #262f3e!important;
  color: #fff!important;
}

.service-list-items-multiple-service .service-list-items {
  border: none;
  padding: 0px 0px;
}

.service-list-items-multiple-service {
  position: relative;
  background: #fff;
  cursor: pointer;
  border: none;
  padding: 10px 0px;
  margin: 0;
  border-top: 1px solid #f7f7f7;
}

.service-list-items-multiple-service h4 {
  font-weight: 600 !important;
  font-size: 15px;
  width: 55%;
  text-align: left !important;
  padding: 10px;
}

.action-icon-img .btn {
  background: transparent;
  border: none;
  color: #777;
  font-size: 20px;
}

.action-icon-img .btn::after {
  display: none;
}

.action-icon-img {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.action-icon-img .btn:hover,
.action-icon-img .btn:focus {
  background: transparent;
  color: #777;
  border: none;
  box-shadow: none;
  outline: none;
}


.no-result {
  background: #fff;
  padding: 50px;
  text-align: center;
  box-shadow: 0px 0px 20px #0000000d;
  border-radius: 5px;
}

.no-result h4{
  margin: 0;
}

.header-buttons .btn:before {
  content: "\f067";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  margin-right: 4px;
}

/************ Add Services ****************/

.partner-add-services-content {
  padding: 20px 0px;
  background: #fff;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 5px;
}

.add-service-box {
  background: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 50px;
  cursor: pointer;
  
}

.add-service-box.bdr-right {
  border-right: 1px dashed #d1d3d8;
}

.add-service-box img {
  width: 30px;
  opacity: .5;
}

.add-service-box h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 5px;
  padding-top: 10px;
  color: #808080;
}

.add-service-box p {
  color: #777;
  max-width: 250px;
  margin: 0px auto;
}

.add-service-box a {
  color: #333;
  display: block;
}

.add-service-box a:hover {
  text-decoration: none;
  color: #333;
}

/* .single-services .admin-title,
.package-services .admin-title,
.add-services .admin-title {
  justify-content: center;
} */

.single-services .admin-title a,
.package-services .admin-title a,
.add-services .admin-title a {
  position: absolute;
  right: 0;
}

/************** Single Service  ****************/

.service-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  margin-bottom: 20px;
}

.service-header {
  padding: 25px 25px;
  padding-bottom: 10px;
}

.service-body {
  padding: 15px 25px;
  
}

.service-header h2 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #8091a1;
  letter-spacing: 0.5px;
}

.service-header p {
  margin: 0;
  color:#8091a1;
}

.treatment-info {
  margin: 0;
  color: #8091a1;
  font-size: 12px;
  padding-top: 8px;
}

.single-services-content textarea {
  height: 120px !important;
}

.package-services-content textarea {
  height: 100px !important;
}

.online-booking {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  color: #8091a1;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.sales-setting h5 {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #8091a1;
  letter-spacing: 0.5px;
}

.sales-setting {
  padding-bottom: 10px;
}

.sales-setting p {
  color: #8091a1;
} 

.staff-item {
  display: flex;
  align-items: center;
  padding: 15px 15px;
  width: 32%;
  font-weight: 600;
  color: #333;
  flex-wrap: wrap;
  position: relative;
  border: 1px dashed #ddd;
  border-radius: 5px;
  text-align: center;
  flex-flow: column;
  font-size: 16px;
  margin-bottom: 5px;
}

.staff-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0px 10px;
  margin-bottom: 10px;
}

.staff-item .form-check {
  position: absolute;
  right: 6px;
  top: 6px;
}

.staff-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.select-all-staff {
  padding-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
  color: #8091a1;
}

.staff-copmmision {
  padding-top: 30px;
}

.staff-copmmision h5 {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #8091a1;
  letter-spacing: 0.5px;
}

.staff-copmmision p {
  color: #8091a1;
}

.extra-time h5 {
  font-weight: 600;
  font-size: 17px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.pricing-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pricing-input .form-group {
  width: 49%;
}

.pricing-input .form-group.full {
  width: 100%;
}

.pricing-option {
  background: #f6f7fb;
  padding: 20px;
  border-radius: 5px;
  margin: 10px 0px;
  position: relative;
}

.remove-pricing {
  position: absolute;
  right: 10px;
  color: #fb3333;
  font-size: 20px;
  cursor: pointer;
}

.pricing-option h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #8091a1;
  letter-spacing: 0.5px;
}

.add-pricing-option span {
  cursor: pointer;
  font-weight: 600;
  color: #2577ff;
  font-size: 15px;
  background: #ffffff;
  padding: 10px 0px;
  border-radius: 4px;
}

.single-services-content .online-booking {
  position: absolute;
  right: 20px;
  bottom: 30px;
}

.add-pricing-option {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-button.service-save {
  padding: 20px 0px;
  display: flex;
  justify-content: flex-start;

}

.service-save .l-btn.btn.btn-primary {
  background: #262f3e;
}

.service-save .btn {
  max-width: 150px;
  margin-right: 10px;
}

.service-category-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f1f1;
  padding: 12px 15px;
  border-radius: 5px;
  flex-wrap: wrap;
}

.service-category-list-items ul {
  margin: 0;
}

.service-category-list-items ul li {
  font-weight: 500;
  font-size: 16px;
}

.category-edit span {
  color: #018bcf;
  font-weight: 600;
  cursor: pointer;
}

.service-category-list-items {
  width: 80%;
}

.category-edit {
  width: 20%;
  text-align: right;
}

.select-categories .select-list {
  padding: 15px 0px;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.select-categories .select-list h5 {
  margin: 0;
  font-size: 18px;
}

.select-categories .select-list p {
  margin: 0;
  font-size: 12px;
  color: #999;
}

.select-categories .select-list:last-child {
  border: none;
}

.select-categories + .login-button {
  margin-top: 20px;
}

.add-service-price i {
  margin-left: 10px;
  color: #f34949;
}

.extra-option h5 {
  padding-top: 15px;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 15px;
  text-transform: uppercase;
  color: #8091a1;
  letter-spacing: 0.5px;
}

.select-services .select-categories {
  padding-bottom: 20px;
}

.select-service-popup .modal-dialog {
  max-width: 800px;
}

.accordion .btn {
  width: 100%;
  text-align: left;
  color: #333;
  text-decoration: none;
  padding: 10px 0px;
  font-size: 20px;
  font-weight: 500;
}

.accordion .btn:focus {
  box-shadow: none;
}

.accordion .card-header {
  background: #fff;
  border: none;
  padding: 0;
}

.accordion .card {
  border: none;
  border-bottom: 1px solid #ddd !important;
}

.accordion .btn:hover,
.accordion .btn:focus {
  color: #333;
  text-decoration: none;
}

.accordion .btn i {
  position: absolute;
  right: 0;
  top: 15px;
}

.accordion .card:last-child {
  border-bottom: none !important;
}

.accordion .card-body {
  padding: 0px 20px;
}

.add-service-price {
  position: absolute;
  right: 10px;
  top: 20px;
  font-weight: 600;
  font-size: 18px;
}


.service-pricing .input-group-prepend {
  padding: 0px 10px;
  border: 1px solid #ced4da;
}


button.l-btn.delete-btn.btn.btn-primary {
  background-color: #ea3333 !important;
}


.pricing-input .input-group-prepend {
  margin-right: 10px;
}



.partner-header h2 {
  font-size: 16px;
  text-transform: uppercase;
  color: #8091a1;
  margin: 0;
}

.partner-header {
  padding-bottom: 25px;
}

.partner-table table thead th {
  color: #8091a1;
  font-weight: 700;
  border: none;
  padding: 15px 10px;
  border-top: 1px solid #f1f1f1;
  letter-spacing: 0.2px;
}

.partner-table table tbody td {
  color: #808e9b;
  padding: 25px 10px;
  font-size: 15px;
  border-top: 1px solid #f1f1f1;
}


/******************************* Media SCreen ******************************/

@media screen and (max-width: 767px) {
  .admin-container {
    width: 100%;
  }

  .admin-menu {
    width: 0;
  }

  .admin-search .form-inline {
    justify-content: center;
  }

  .admin-search .btn {
    margin-top: 10px;
  }

  .user-list .table-responsive > .table-bordered .btn {
    min-width: 100px;
    margin-bottom: 5px;
  }

  .admin-menu.menu-hide {
    width: 250px;
  }
}

.package-services .l-btn.btn.btn-primary {
  width: auto !important;
}
.package-services .delete-btn {
  background-color: #ea3333 !important;
}
.disabled {
  cursor: not-allowed;
  opacity: .3;
  background: #ccc;
}
.partner-table table .disabletable tr:first-child {

  opacity: .3;
  background: #ccc;
  cursor: not-allowed;
}
.disable-block {
  opacity: .5;
  cursor: not-allowed;
}

.disable-block input {
  pointer-events: none;
}

.disabled input {
  pointer-events: none;
}

.pdd-bt-89{
  padding-bottom: 89px;
}

.advance-pricing-popup{
  font-family: 'Nunito', sans-serif!important;
}
.advance-pricing-box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background: #f7f7f7;
  border-radius: 5px;
}

.advance-pricing-box .form-group {
  width: 24%;
}

.special-price-associate {
  padding: 20px 0px;
}

.associate-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
}

.associate-user img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.associate-user {
  display: flex;
  align-items: center;
  width: 40%;
}

.associate-user p {
  margin: 0;
  padding-left: 15px;
  font-weight: 600;
  color: #8091a1;
}

.price-associate {
  width: 25%;
  padding-right: 10px;
}

.duratiion-associate {
  width: 25%;
}

.special-price-associate h4 {
  font-weight: 600;
  color: #555;
  font-size: 20px;
}
.advance-pricing-popup .modal-title {
  font-weight: 700;
  color: #555;
}

.associate-pricing-buttons {
  text-align: right;
}

.associate-pricing-buttons .btn.btn-default {
  border: 1px solid #ddd;
  color: #999;
  margin-right: 10px;
}

.associate-pricing-buttons .btn.btn-primary {
  background: #262f3e;
  border-color:#262f3e;
}

.associate-pricing-buttons .btn.btn-primary:hover {
  border: 1px solid #262f3e!important;
}

/**********SetUP*******************/
.account-card {
  margin-bottom: 30px;
}

.account-card h2 {
  line-height: 28px;
  margin: 0;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  color: #555;
}
.account-card a {
  font-weight: 600;
  margin-bottom: 0px;
  display: block;
  font-size: 16px;
  color: #6f6f6f;
  padding: 15px 20px;
}
.account-card .card-body {
  padding: 0;
}
.account-card .card {
  border: 1px solid #f7f7f8;
  padding: 10px;
  box-shadow: 0 0px 5px 0 rgb(164 173 186 / 8%);
  margin-bottom: 20px;
}
.account-card .card-title.h5 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.tax-head img {
  width: 10%;
}
.btn-group-toggle .active1 {
  color: #fff !important;
  background-color: #007bff !important;
}

.account-card a:hover {
  text-decoration: none;
  color: #333;
}

.input-group-prepend .fa {
  font: normal normal normal 14px/1 FontAwesome;
}

/*********************ACCOUNT SETTING*******************/

p.text-12 {
  background-color: #eef0f2;
  padding: 14px 16px;
  text-align: left;
  border-radius: 2px;
}
.info-card {
  margin-bottom: 30px;
}

.back-setup h5 {
  font-weight: 600;
}

.account-setting .card-title.h5 {
  font-weight: 600;
}
.account-setting .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem !important;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef !important;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.account-setting .card:hover {
  box-shadow: 0 5px 15px 5px rgba(164, 173, 186, 0.25);
}
.account-setting .card {
  border: 1px solid #f7f7f8;
  padding: 10px;
  box-shadow: 0 2px 5px 0 rgba(164, 173, 186, 0.25);
}

.account-save{
  text-align: right;
}
.account-save .btn{
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.account-save .btn:hover{
  border: 1px solid  #0075ff;
}

.invoice-tab-content {
  padding: 10px 0px;
  background: #fff;
  border-radius: 4px;
}

.invoice-tab-content .card {
  border: none;
}

.invoice-tab-content .card .card-body {
  padding: 10px;
}

.invoice-tab-content .online-booking {
  padding: 0;
}

.invoice-tab-content .input-group-prepend {
  align-items: center;
  margin: 10px 0px
  
}

.invoice-tab-content .input-group-prepend .form-label {
  margin: 0;
}
/*********************INVOICE*******************/

.invoice input[type='checkbox'] {
  margin-top: -8px !important;
  margin-right: 10px;
  width: 25px;
  height: 18px;
}


/*********************INVOICE-SEQUENCE*******************/


.invoice-sequence table tr td span {
  cursor: pointer;
}

.invoice-sequence .partner-table .btn.btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
}

.invoice-sequence .partner-table .btn.btn-primary:hover {
  border: 1px solid #0075ff;
}

.discount-type .btn-primary{
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.discount-type .btn-primary{
  border: 1px solid #0075ff;
}

.modal, .discount-12{
  font-family: 'Nunito', sans-serif!important;
}

.discount-12 .btn-primary {
  border: 1px solid #007bff;
}

/*********************TAXES*******************/
.taxes {
  padding: 30px 20px;
  background: #fff;
  border-radius: 4px;
}


.taxes .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.taxes .btn-primary:hover {
  border: 1px solid #0075ff;
}

.taxes .partner-table .btn-primary {
  margin-left: 5px;
  padding: .375rem .75rem;
  font-size: 1rem;
}

.taxes h5 {
  margin: 0;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  color: #555;
}
.cus-12 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tax-head span.mb-5 {
  display: inline-block;
}
.default-tax {
  border-top: 1px solid #eee;
  padding-top: 50px;
}
.tax-head {
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0 30px 0;
}

.tax-head i.fa.fa {
  font-size: 60px;
  color: #999;
  margin-bottom: 10px;
}
.taxes p {
  color: #999;
}
.tax-show .card {
  background: #f7f7f7;
  border-color: #f3f0f0;
}

.tax-show .card h5 {
  padding-bottom: 5px;
}

.tax-calculation span{
  cursor: pointer;
}

.tax-sale {
  background: #262f3e;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.tax-cal-box {
  display: flex;
  align-items: flex-start;
  padding: 20px 0px;
}

.cal-box-detail h4 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding-bottom: 5px;
  color: #555;
}

.cal-box-detail p {
  margin: 0;
  color: #999;
}

.cal-box-detail {
  padding-left: 15px;
}

.tax-cal-box .online-booking {
  padding: 0;
}

.tax-calculation-popup .modal-body {
  padding: 25px;
}

.tax-calculation-popup .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.tax-calculation-popup .btn-primary:hover {
  border: 1px solid #0075ff;
}

.tax-calculation-popup .modal-dialog{
  max-width: 600px;
}

/*********************PAYMENT TAXES MODAL*******************/

.pay-12 .modal-dialog {
  max-width: 570px !important;
}

.payment-tax {
  padding: 30px 20px;
  background: #fff;
  border-radius: 4px;
}

.payment-tax .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.payment-tax .btn-primary:hover{
  border: 1px solid #0075ff;
}

.payment-tax .partner-table .btn-primary {
  margin-left: 5px;
  padding: .375rem .75rem;
  font-size: 1rem;
}

.payment-tax .partner-table table thead th:last-child {
  width: 150px;
}

.pay-12 .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.pay-12 .btn-primary:hover {
  border: 1px solid #0075ff;
}

.discount-12 .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.discount-12 .btn-primary:hover {
  border: 1px solid #0075ff;
}


/*********************DISCOUNT TYPE*******************/

.dic-info {
  box-shadow: 0 8px 15px 5px rgba(164, 173, 186, 0.2);
  background-color: #eef0f2;
  border-radius: 4px;
  overflow: hidden;
}

.dic-info .card {
  border: 1px solid #fff;
}

/*********************DISCOUNT TYPE MODAL*******************/

.discount-12 input[type='checkbox'] {
  margin-top: -10px !important;
  margin-right: 10px;
  width: 20px;
  height: 18px;
}

.discount-12 .row .input-group-text {
  padding: 0.375rem 0.75rem !important;
  background-color: #e9ecef !important;
  border: 1px solid #ced4da !important;
}

.staff-content {
  padding: 10px 0px;
  background: #fff;
  border-radius: 4px;
}

.staff-page .nav-tabs .nav-link {
  font-size: 15px;
  font-weight: 600;
  width: 25% !important;
  text-align: center;
  padding: 15px 15px;
  color: #8091a1;
}
.staff-page a {
  font-weight: 500;
  color: #000;
  font-size: 17px;
}
.staff-page a:hover {
  color: #3c3b3b;
}

.staff-page .nav-tabs {
  border-bottom: 2px solid #efefef;
}
.staff-page .tab-content {
  border: none;
  margin-top: 0;
  padding: 20px 20px 30px;
}

.staff-content .btn,
.staff-content .btn:hover {
  border: none !important;
}

/********* Close Staff ****************/

.close-date button {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}
.close-date {
  text-align: right;
  padding-bottom: 20px;
}
.close-date button:hover {
  background-color:#0075ff !important;
  border-color:#0075ff !important ;
}
.staff-page .nav-tabs .nav-link.active {
  color: #8091a1!important;
  background-color: transparent !important;
  border-color: transparent !important;
  border-bottom: 2px solid #0075ff!important;
}
.staff-page .nav-tabs .nav-link.active:hover,
.staff-page .nav-tabs .nav-link.active:focus {
  border-bottom: 2px solid #0075ff!important;
}
.staff-page .nav-tabs .nav-link:focus,
.staff-page .nav-tabs .nav-link:hover {
  border-color: transparent !important;
 
}

.closedatemodal .btn.btn-primary {
  background-color: #018bcf;
}
.closedatemodal .modal-content {
  width: 560px;
}
.closedatemodal .modal-header {
  border-bottom: transparent !important;
}

/*************** User Permission *************/

span.input-group-text {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

thead th.text-heading {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.user-permission button {
  background-color: #018bcf !important;
  border-color: #018bcf !important;
}
.user-12 p.text-muted {
  margin-bottom: 30px;
}

.user-permission p {
  max-width: 780px;
}

/********* Add Staff ****************/

.add-staff-content {
  background: #fff;
  padding: 40px 30px;
}

.add-staff-details .form-control {
  height: auto;
  padding: 12px 20px;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 4px;
}
.add-staff .s-btn.btn.btn-primary {
  width: 20%;
  margin-right: 10px;
  box-shadow: 0px 3px 10px #00000038;
}
.add-staff-content .nav-tabs .nav-link {
  padding: 15px 50px;
}
.add-staff-details textarea#employee_notes {
  height: 137px;
}
.c-tn.btn.btn-primary {
  background-color: #9aa09e;
}
.mt {
  margin-top: 20px;
}

.add-staff-content .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 600;
  color: #8091a1;
}

.close-date a {
  background-color:  #0075ff;
  color: #fff;
  border-color:  #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
  display: inline-block;
  box-shadow: 0px 3px 10px #00000038;
}

.close-date a:hover,
.close-date a:focus {
  color: #fff;
  text-decoration: none;
  background:  #0075ff;
}

.add-services input[type='checkbox'] {
  margin-top: -8px !important;
  margin-right: 10px;
}
.add-services .sel-1.form-label {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.add-services .form-label {
  font-weight: 500;
  color: #555;
}

/********* Add Commision ****************/

.add-commission .input-group-prepend {
  margin-right: -1px;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  padding: 3px 15px;
}

/********* StaffWorking Hours ****************/

.schedule-filters .form-control {
  max-width: 135px;
}

.pull-right {
  float: right !important;
}

.schedule-date-toolbar
  .btn-group
  > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.schedule-wrapper {
  position: relative;
  display: flex;
  min-height: 0;
}

.schedule-scrollable {
  overflow-y: auto;
  width: 100%;
  margin-top: 25px;
}

.schedule-tables {
  overflow-y: auto;
}

.schedule-employees {
  float: left;
  width: 20%;
}

.employee-table {
  border-left: 1px solid #d9d9d9;
}

.schedule-table,
.employee-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.staff-working table {
  background-color: transparent;
}

.schedule-employee-hours {
  float: left;
  width: 80%;
}

.schedule-table,
.employee-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.schedule-table th,
.employee-table th {
  padding: 10px;
}

.schedule-table th,
.schedule-table td,
.employee-table th,
.employee-table td {
  text-align: center;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  cursor: default;
  min-width: 152px;
}

.employee-table td.employee-name {
  font-weight: bold;
  vertical-align: top;
  text-align: left;
  padding: 5px 10px 0;
}

.employee-table td {
  height: 51px;
}

.schedule-hours {
  border-radius: 2px;
  color: #24334a;
  padding: 3px;
  text-align: left;
  background: #d5edf5;
  font-size: 14px;
  cursor: pointer;
}

.schedule-date-toolbar .btn-default.active,
.schedule-date-toolbar .btn-default:active,
.schedule-date-toolbar .btn-default.active:focus,
.schedule-date-toolbar .btn-default:active:focus,
.schedule-date-toolbar .btn-default:active:hover {
  background-color: #f7f7f8;
  border-color: #c2c2c2;
  color: #2c2c2c;
}

.pull-right.schedule-date-toolbar {
  border: 1px solid #ced4da;
}

input#date_from {
  display: none;
}

.closedatemodal.edithour .modal-header {
  flex-wrap: wrap;
}

.closedatemodal.edithour .modal-header span {
  width: 100%;
}

.closedatemodal.edithour .modal-header .close {
  position: absolute;
  right: 10px;
  font-weight: 500;
  font-size: 30px;
}
.cross {
  position: absolute;
  right: 0;
  padding: 0px 20px;
  color: black;
}



/*********** Calendar **********/


.schedule-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 40px;
}

.schedule-date-toolbar {
  border: 1px solid #f1f1f1;
  padding: 1px;
  margin-right: 20px;
}

.partner-table table.table-bordered thead th {
  border: 1px solid #f1f1f1;
  text-align: center;
}

.partner-table table.table-bordered tbody td {
  border: 1px solid #f1f1f1;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.partner-table table.table-bordered tbody tr td:first-child {
  text-align: left;
}

.schedule-filters .form-inline .form-control {
  min-width: 120px;
  border: 1px solid #f1f1f1;
  border-radius: 0px;
}

.partner-table table.table-bordered thead th:first-child {
  width: 180px;
  text-align: left;
}

.partner-table table.table-bordered thead th:nth-child(2) {
  width: 113px
}

.add-shift {
  background: #262f3e;
}

.add-shift i {
  color: #fff;
}

.user-permission .online-booking {
  padding: 0;
}

.user-permission .partner-table table tbody td {
  vertical-align: middle;
}

.user-permission .btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.close-staff .partner-table table tbody td .btn-danger {
  margin-left: 5px;
}

.closedatemodal .btn.btn-primary {
  border: 1px solid #0075ff;
}

.closedatemodal {
  font-family: 'Nunito', sans-serif!important;
}

.closedatemodal .btn.btn-primary {
  background-color: #0075ff;
  color: #fff;
  border-color: #0075ff;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.closedatemodal .btn.btn-secondary {
  background-color: #696a6b;
  color: #fff;
  border-color: #696a6b;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}
.form-group .number-fields .dropdown .btn {
  border: 1px solid #dadada!important;
}
.add-staff-content .tab-content {
  padding: 25px;
  border: 1px solid #ddd;
  margin-top: -1px;
}
.disabled-permmission {
  cursor: not-allowed;
  opacity: 0.3;
}

.disabled-permmission input[type="checkbox"] {
  pointer-events: none;
}

.disabled-permmission .slider {
  cursor: not-allowed;
}
@media screen and (max-width: 991px) {

.container, .container-lg, .container-md, .container-sm {
    max-width: 100%;
}

.navbar-light .navbar-brand img {
    max-width: 200px;
}

.search-container {
    width: 75%;
}

}


@media screen and (max-width: 767px) {

    .navbar-light .navbar-brand img {
        max-width: 225px;
    }

    .scrollable-category .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .header-right {
        display: none;
    }
    .banner-content h2 {
        font-size: 35px;
    }
    .banner-content {
        padding: 30px;
    }
    .banner-content p {
        font-size: 14px;
    }
    .home-banner {
        padding: 125px 0px;
    }
    .category-info {
        padding: 10px 10px;
    }
    .category-info h2 {
        font-size: 14px;
    }

    .sidebar-menu {
        padding: 10px 0px;
    }

    .side-nav-content ul.main-navigation li a {
        font-size: 14px;
    }

    .sidebar-wrapper {
        width: 80vw;
        left: -81vw;
        padding: 50px 25px;
    }

    .side-nav-content ul.main-navigation li {
        margin: 25px 0;
    }

    .side-nav-content ul.main-navigation {
        margin: 0;
    }

    .side-nav-content .axil-contact-info-inner .axil-contact-info p {
        font-size: 14px;
    }

    .side-nav-content .axil-contact-info-inner h5.title,
    .side-nav-content .axil-contact-info-inner span.title {
        font-size: 16px;
        font-weight: 600;
        padding-top: 20px;
    }

    .close-icon {
        right: -10px;
        top: -30px;
    }

    .featured-video {
        padding-bottom: 50px;
    }

    .featured-video > div {
        height: 300px !important;
    }

    .sub-title {
        padding-bottom: 30px;
    }

    .section-b-space {
        padding-bottom: 0px;
    }

    .sub-footer ul {
        flex-wrap: wrap;
    }

    .sub-footer ul li {
        width: 50%;
        text-align: center;
        padding: 5px 0px;
    }



    /******* Store ******/

    .store-listing-filter {
        flex-wrap: wrap;
    }

    .store-filter-box {
        justify-content: center;
        flex-wrap: wrap;
    }

    .store-list {
        width: 100%;
        padding: 0;
    }

    .store-image {
        width: 100%;
    }

    .store-detail {
        width: 100%;
        padding: 20px;
    }

    .store-image img {
        width: 100%;
        height: 220px;
    }


}

