footer {
    position: relative;
}
.footer-social .fa {
    font: normal normal normal 20px/1 FontAwesome;
}
.light-layout-white {
    background-color: #ffffff;
}
.light-layout {
    background-color: #f9f9f9;
}

.border-section {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.small-section {
    padding-top: 35px;
    padding-bottom: 35px;
}
.border-top-0 {
    border-top: 0!important;
}

.footer-light .subscribe {
    display: flex;
    height: 100%;
    align-items: center;
    border-right: 1px solid #ddd;
    text-align: left;
}

.subscribe h4 {
    color: #222;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
}
.footer-light .subscribe p {
    line-height: 1;
    letter-spacing: .03em;
    margin-bottom: 0;
    font-size: 14px;
    color: #bdbdbd;
}

.footer-light .subscribe-form {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
}

.subscribe-form .form-group {
    margin-bottom: 0;
}

.subscribe-form .form-control {
    padding: 13px;
    width: 215px;
    border-radius: 0;
    border: 1px solid #eee;
    vertical-align: middle;
    display: inline-block;
}

.btn-solid {
    padding: 13px 29px;
    color: #fff!important;
    letter-spacing: .05em;
    border: 2px solid #f1454f;
    background-image: -webkit-linear-gradient(30deg,#f1454f 50%,transparent 0);
    background-image: -webkit-linear-gradient(60deg,#f1454f 50%,transparent 0);
    background-image: linear-gradient(30deg,#f1454f 50%,transparent 0);
    background-size: 540px;
    background-repeat: no-repeat;
    background-position: 0;

}

.section-b-space {
    padding-bottom: 20px;
    padding-top: 50px;
}

.footer-theme .footer-logo {
    margin-bottom: 18px;
}
footer p {
    line-height: 32px;
    letter-spacing: .03em;
    margin-bottom: 0;
    color:#bdbdbd;
}
.footer-social, .social-white {
    padding-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-logo img {
    max-width: 150px;
}

.footer-social li, .social-white li {
    display: inline-block;
    padding-right: 15px;
    padding-left: 15px;
}


.footer-social ul, .social-white ul {
    padding-left: 0;
    margin-bottom: 0;
}

.footer-social i, .social-white i {
    font-size: 20px;
    color: #bdbdbd;
    -webkit-transition: .5s ease;
    transition: .5s ease;
}

.footer-theme .sub-title h4 {
    color: #222;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
}

.footer-theme .sub-title li, .footer-theme .sub-title li a {
    position: relative;
    color: #bdbdbd;
}
.footer-theme .sub-title li {
    display: inherit;
    padding-top: 13px;
    text-transform: capitalize;
    font-size: 14px;
}

.footer-theme .sub-title li a:before {
    position: absolute;
    top: 20px;
    content: "";
    height: 2px;
    width: 0;
    background-color: #ff4c3b;
    -webkit-transition: .5s ease;
    transition: .5s ease;
}

.footer-theme .sub-title .contact-list li {
    position: relative;
    padding-left: 25px;
    line-height: 20px;
}

.footer-theme .sub-title .contact-list i {
    top: 17px;
}
.footer-theme .sub-title .contact-list i {
    position: absolute;
    left: 0;
    top: 17px;
}

.sub-footer ul li a:hover {
    text-decoration: none;
    color: #018ccf;
}

.sub-footer p {
    color: #bdbdbd;
    margin-bottom: 0;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    
}


.sub-footer .payment-card-bottom {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.sub-footer .payment-card-bottom li {
    padding-left: 7px;
    padding-right: 7px;
    display: inline-block;
}

dl, ol, ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}


.footer-theme .sub-title li a:hover {
    color:#018ccf;
}

.footer-social li a:hover i{
    color:#018ccf;
}

.sub-title {
    text-align: center;
}

.footer-title h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 25px;
    color: #fff;
}

.footer-contant p {
    line-height: 22px;
    margin-bottom: 0;
    color: #bdbdbd;
    font-size: 14px;
    max-width: 200px;
    margin: 0px auto;
}

.footer-light .container {
    max-width: 998px;
}

ul.contact-list li a, ul.contact-list li {
    font-size: 14px;
    color: #bdbdbd;
}

ul.contact-list li {
    padding-bottom: 3px;
}

.sub-footer ul {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-footer ul li {
    padding: 0px 15px;
}

.sub-footer ul li a {
    color: #bdbdbd;
    font-size: 14px;
}

.footer-dark {
    background: #131111;
}